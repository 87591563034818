<template>
  <div>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
        返回上级
      </el-button>
      <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>
    </el-card>
    <div class="table-container">
      <el-table ref="memberTable"
                :data="list"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">{{ scope.row.realName }}</template>
        </el-table-column>
        <el-table-column label="团队负责人" align="center">
          <template slot-scope="scope">{{ scope.row.isLeader ? '是':'否' }}</template>
        </el-table-column>
        <el-table-column label="所属部门" align="center">
          <template slot-scope="scope">{{ scope.row.dept }}</template>
        </el-table-column>
        <el-table-column label="岗位" align="center">
          <template slot-scope="scope">{{ scope.row.deptTitle }}</template>
        </el-table-column>
        <el-table-column label="联系电话" align="center">
          <template slot-scope="scope">{{ scope.row.tel }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="text"
                       @click="handleUpdate(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button size="mini"
                       type="text"
                       @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          :page-sizes="[10,15,20]"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="isEdit ? '编辑项目成员' : '添加项目成员'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="editMemberForm"
               :rules="userRules"
               label-width="100px" size="small">
        <el-form-item label="成员：" prop="userId">
          <el-select
              v-model="editForm.userId"
              :disabled="isEdit"
              filterable
              remote
              clearable
              :placeholder="editForm.realName ? editForm.realName : '请输入成员姓名'"
              :remote-method="searchUserByKeyword"
              @change="handleUserChange"
              :loading="loadingSearch">
            <el-option
                v-for="member in searchedMemberList"
                :key="member.id"
                :label="member.realName + ' ' + member.tel"
                :value="member.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话">
          <el-input disabled v-model="editForm.tel"></el-input>
        </el-form-item>
        <el-form-item label="团队负责人">
          <el-switch
              v-model="editForm.isLeader"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('editMemberForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

const defaultMember = {  //项目具体成员
  id: null,
  projectId: null,
  userId: null,
  isLeader: false,
  isDelete: false,
  realName: null,
  tel: null,
  dept: null,
  deptTitle: null
};
export default {
  name: "projectMemberList",
  data() {
    return {
      listQuery: {
        projectId: null,
        pageNum: 1,
        pageSize: 10
      },
      list: null,
      total: null,
      listLoading: false,
      isEdit: false,
      dialogVisible: false,
      projectId: null,
      editForm: Object.assign({}, defaultMember),
      searchedMemberList: [],
      loadingSearch: false,
      userRules: {  //表单校验
        userId: [
          {required: true, message: '请选择项目成员', trigger: 'blur'}
        ]
      }
    }
  },
  created() {
    this.listQuery.projectId = this.$route.query.id;
    this.projectId = this.$route.query.id;
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleBackToTop() {
      this.$router.back();
    },
    handleAdd() {
      this.isEdit = false;
      this.editForm = Object.assign({}, defaultMember);
      this.editForm.projectId = this.projectId;
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该成员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/project/deleteMember?id=' + row.id).then(response => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        });
      });
    },
    handleUpdate(index, row) {
      this.isEdit = true;
      this.editForm = row;
      this.dialogVisible = true;
    },
    getList() {
      this.listLoading = true;
      this.getRequest('/project/getMemberList', this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
      });
    },
    searchUserByKeyword(query) {
      if (query && query !== '') {
        this.loadingSearch = true;
        this.searchedMemberList = [];
        this.getRequest('/user/findEmployees', {keyword: query}).then(response => {
          let tmpList = response.obj;
          if (tmpList && tmpList.length > 0) {
            for (let tmpUser of tmpList) {
              if (!this.editForm.userId || tmpUser.id !== this.editForm.userId) {
                this.searchedMemberList.push(tmpUser);
              }
            }
          }
          this.loadingSearch = false;
        });
      } else {
        this.$message({
          message: '请输入成员中文名关键字',
          type: 'success',
          duration: 1000
        });
      }
    },
    handleUserChange(val) {
      console.log(val)
      for (let i = 0; i < this.searchedMemberList.length; i++) {
        if (this.searchedMemberList[i].id === val) {
          this.editForm.realName = this.searchedMemberList[i].realName;
          this.editForm.dept = this.searchedMemberList[i].dept;
          this.editForm.deptTitle = this.searchedMemberList[i].deptTitle;
          this.editForm.tel = this.searchedMemberList[i].tel;
          break;
        }
      }
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let url = '/project/updateMember';
            this.postRequest(url, this.editForm).then(resp => {
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            })
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>