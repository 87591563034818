<template>
  <div>
    <el-card shadow="never">
      <i class="el-icon-picture"></i>
      <span style="font-size: 24px;">主图列表</span>
      <el-button size="mini" style="float: right;" @click="handleAdd()">添加</el-button>
    </el-card>
    <div>
      <el-table
          :data="list"
          style="width: 100%"
          border>
        <el-table-column
            label="编号"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" width="200" align="center">
          <template slot-scope="scope"><img style="height: 80px" :src="scope.row.imgUrl ? scope.row.imgUrl : require('@/assets/logo.png')"></template>
        </el-table-column>
        <el-table-column
            label="图片名" align="center">
          <template slot-scope="scope">
            {{ scope.row.imgAlt }}
          </template>
        </el-table-column>
        <el-table-column
            label="展示时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.startTime | formatDate("yyyy-MM-dd hh:mm:ss") }} 至 {{ scope.row.endTime | formatDate("yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="排序"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.sortNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="handleStatusChange(scope.$index, scope.row)"
                v-model="scope.row.openFlag">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEdit?'编辑主图':'添加主图'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="orgAlbum"
               ref="albumInfoForm"
               :rules="rules"
               label-width="120px" size="small">
        <el-form-item label="图片(建议分辨率1227*545)" prop="imgUrl">
          <single-upload v-model="orgAlbum.imgUrl"></single-upload>
        </el-form-item>
        <el-form-item label="图片名">
          <el-input v-model="orgAlbum.imgAlt" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="展示时间">
          <el-date-picker class="green-hover"
                          popper-class="green-selected"
                          v-model="showDuration"
                          style="width:300px"
                          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序">
          <el-input type="number" v-model="orgAlbum.sortNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('albumInfoForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload';

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10
};
const defaultAlbum = {
  id: null,
  imgAlt: null,
  imgUrl: null,
  startTime: null,
  endTime: null,
  openFlag: true,
  sortNum: 0
};

export default {
  name: 'albumList',
  components: {SingleUpload},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      orgAlbum: Object.assign({}, defaultAlbum),
      showDuration: [],
      list: [],  //分类数据
      total: 0, //总记录数
      isEdit: false,
      dialogVisible: false,
      rules: {
        imgUrl: [{required: true, message: '请选择图片', trigger: 'blur'}],
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    //初始化分类数据
    getList() {
      const _this = this
      this.getRequest('/org/getAlbumList', this.listQuery).then(resp => {
        if (resp.obj) {
          _this.list = resp.obj.list
          _this.total = resp.obj.total
        }
      })
    },
    handleAdd() {
      this.dialogVisible = true;
      this.isEdit = false;
      this.orgAlbum = Object.assign({}, defaultAlbum);
      this.showDuration = [];
    },
    //编辑
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.orgAlbum = Object.assign({}, row);
      if (this.orgAlbum.startTime) {
        this.showDuration.push(this.orgAlbum.startTime);
        this.showDuration.push(this.orgAlbum.endTime);
      }
    },
    handleStatusChange(index, row) {
      this.$confirm('是否要修改该状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest('/org/updateAlbum', row).then(response => {
          this.$message({
            message: '修改成功！',
            type: 'success'
          });
          this.getList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消修改'
        });
        this.getList();
      });
    },
    //删除分类
    handleDelete(index, row) {
      console.log(index, row);
      const _this = this
      this.$confirm('此操作将删除该主图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/org/deleteAlbum?id=' + row.id).then(resp => {
          if (resp) {
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.listQuery.pageNum = val;
      this.getList()
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.showDuration.length > 0) {
              this.orgAlbum.startTime = this.showDuration[0];
              this.orgAlbum.endTime = this.showDuration[1];
            } else {
              this.orgAlbum.startTime = null;
              this.orgAlbum.endTime = null;
            }

            this.postRequest('/org/updateAlbum', this.orgAlbum).then(response => {
              this.$message({
                message: this.isEdit ? '修改成功！' : '添加成功！',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            })
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          });
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>