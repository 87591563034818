import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';  //引入Element ui
import {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import {postKeyValueRequest} from "./utils/api";
import {postPicRequest} from "./utils/api";
import {postRequest} from "./utils/api";
import {putRequest} from "./utils/api";
import {getRequest} from "./utils/api";
import {deleteRequest} from "./utils/api";

import vRegion from 'v-region'

Vue.use(vRegion)

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// 使用 mavonEditor
Vue.use(mavonEditor)

Vue.prototype.postKeyValueRequest = postKeyValueRequest;
Vue.prototype.postPicRequest = postPicRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.$store = store

Vue.filter('formatDate', function (value, fmt) {
    if (!value) {
        return "--";
    }
    var date = new Date(value);
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "w+": date.getDay(), //星期
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (k === 'w+') {
            if (o[k] === 0) {
                fmt = fmt.replace('w', '周日');
            } else if (o[k] === 1) {
                fmt = fmt.replace('w', '周一');
            } else if (o[k] === 2) {
                fmt = fmt.replace('w', '周二');
            } else if (o[k] === 3) {
                fmt = fmt.replace('w', '周三');
            } else if (o[k] === 4) {
                fmt = fmt.replace('w', '周四');
            } else if (o[k] === 5) {
                fmt = fmt.replace('w', '周五');
            } else if (o[k] === 6) {
                fmt = fmt.replace('w', '周六');
            }
        } else if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
})


Vue.config.productionTip = false

Vue.use(ElementUI); //使用 ElementUI

router.beforeEach((to, from, next) => {
    //to: 将要去哪里  from: 从哪里来的  next: 下一步，放行
    //如果要去的页面是Login页，直接放行
    if (to.path == '/' || to.path == '/admin') {
        next();
    } else {
        //如果有user，说明已经登录了
        if (store.getters.isLogin) {
            next();
        } else {
            //没有登录就去登录页并记录你刚才要去的路由
            //to.path是你刚要去的路由，但因未登录被拦截了，在登录之后就会直接去到你刚访问的路由
            Message.error({message: "尚未登录，请先登录"})
            next('/?redirect=' + to.path);
        }

    }
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
