<template>
  <div>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
        返回上级
      </el-button>
      <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>
    </el-card>
    <div class="table-container">
      <el-table ref="itemTable"
                :data="list"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="房号" align="center">
          <template slot-scope="scope">{{ scope.row.roomNum }}</template>
        </el-table-column>
        <el-table-column label="楼层" align="center">
          <template slot-scope="scope">{{ scope.row.storey }}</template>
        </el-table-column>
        <el-table-column label="所处方位" align="center">
          <template slot-scope="scope">{{ scope.row.positionDesc }}</template>
        </el-table-column>
        <el-table-column label="售卖单价（元/㎡）" align="center">
          <template slot-scope="scope">￥{{ scope.row.unitPrice }}</template>
        </el-table-column>
        <el-table-column label="租赁单价（元/㎡/天）" align="center">
          <template slot-scope="scope">￥{{ scope.row.rentUnitPrice }}</template>
        </el-table-column>
        <el-table-column label="总价/月租（元）" align="center">
          <template slot-scope="scope">￥{{ scope.row.totalPrice }}</template>
        </el-table-column>
        <el-table-column label="当前状态" align="center">
          <template slot-scope="scope">{{ itemStatusLabel[scope.row.itemStatus] }}</template>
        </el-table-column>
        <el-table-column label="设置" width="120" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                @click="handleChangeStatus(scope.$index, scope.row)">更改状态
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="text"
                       @click="handleUpdate(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button size="mini"
                       type="text"
                       @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          :page-sizes="[10,15,20]"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="isEdit ? '编辑房源信息' : '添加房源'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="editItemForm"
               :rules="itemRules"
               label-width="180px" size="small">
        <div class="form-part-section">
          <el-form-item label="房号" prop="roomNum">
            <el-input
                placeholder="请输入房号"
                v-model="editForm.roomNum">
            </el-input>
          </el-form-item>
          <el-form-item label="所在楼层" prop="storey">
            <el-input type="number" v-model="editForm.storey"></el-input>
          </el-form-item>
          <el-form-item label="所处方位">
            <el-input placeholder="如位于楼层西南角" v-model="editForm.positionDesc"></el-input>
          </el-form-item>
          <el-form-item label="当前状态">
            <el-select v-model="editForm.itemStatus" placeholder="请选择">
              <el-option
                  v-for="item in itemStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="售卖单价（元/㎡）" required>
            <el-input type="number" v-model="editForm.unitPrice"></el-input>
          </el-form-item>
          <el-form-item label="租赁单价（元/㎡/天）" required>
            <el-input type="number" v-model="editForm.rentUnitPrice"></el-input>
          </el-form-item>
          <el-form-item label="单价信息客户是否可见">
            <el-switch
                v-model="editForm.unitPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="总价/月租（元）">
            <el-input type="number" v-model="editForm.totalPrice"></el-input>
          </el-form-item>
          <el-form-item label="总价信息客户是否可见">
            <el-switch
                v-model="editForm.totalPublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="价格构成">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入价格构成"
                v-model="editForm.priceInclude">
            </el-input>
          </el-form-item>
          <el-form-item label="价格构成信息客户是否可见">
            <el-switch
                v-model="editForm.includePublic"
                active-text="可见"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="房源相册">
            <multi-upload v-model="albums"></multi-upload>
          </el-form-item>
        </div>
        <div class="form-part-section" v-if="editForm.itemStatus === 4">
          <el-form-item label="成交总结">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入成交总结"
                v-model="editForm.finalCondition">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('editItemForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="房源状态更新"
        :visible.sync="statusDialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="itemStatusForm"
               label-width="150px" size="small">
        <el-form-item label="最新状态:">
          <el-select v-model="editForm.itemStatus" placeholder="请选择">
            <el-option
                v-for="item in itemStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="statusDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleStatusConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import multiUpload from '@/components/Upload/multiUpload';

const defaultSubItem = {  //项目具体表单
  id: null,
  itemId: null,
  projectId: null,
  roomNum: null,
  storey: null,
  itemStatus: 0, // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
  positionDesc: null,
  albumPics: null,
  unitPrice: null,
  rentUnitPrice: null,
  unitPublic: false,
  totalPrice: null,
  totalPublic: false,
  priceInclude: null,
  includePublic: false,
  finalCondition: null
};
export default {
  name: "subItemList",
  data() {
    return {
      listQuery: {
        itemId: null,
        pageNum: 1,
        pageSize: 10
      },
      list: null,
      total: null,
      listLoading: false,
      isEdit: false,
      dialogVisible: false,
      statusDialogVisible: false,
      itemId: null,
      projectId: null,
      editForm: Object.assign({}, defaultSubItem),
      albums: [],
      itemStatusOptions: [{ // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
        value: 0,
        label: '待售'
      }, {
        value: 1,
        label: '意向订'
      }, {
        value: 2,
        label: '已预付'
      }, {
        value: 3,
        label: '已定'
      }, {
        value: 4,
        label: '已交接完成'
      }, {
        value: 9,
        label: '已下架'
      }],
      itemStatusLabel: { // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
        0: '待售',
        1: '意向订',
        2: '已预付',
        3: '已定',
        4: '已交接完成',
        9: '已下架'
      },
      itemRules: {  //表单校验
        roomNum: [
          {required: true, message: '请输入房间号', trigger: 'blur'}
        ],
        storey: [
          {required: true, message: '请输入所在楼层', trigger: 'blur'}
        ],
      }
    }
  },
  components: {multiUpload},
  created() {
    this.listQuery.itemId = this.$route.query.id;
    this.itemId = this.$route.query.id;
    this.projectId = this.$route.query.projectId;
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleBackToTop() {
      this.$router.back();
    },
    handleAdd() {
      this.isEdit = false;
      this.editForm = Object.assign({}, defaultSubItem);
      this.editForm.itemId = this.itemId;
      this.editForm.projectId = this.projectId;
      this.albums = [];
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该房源?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/projectItem/deleteSubItem?id=' + row.id).then(response => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        });
      });
    },
    handleUpdate(index, row) {
      this.isEdit = true;
      this.editForm = row;
      this.albums = row.albums ? row.albums.split(',') : [];
      this.dialogVisible = true;
    },
    handleChangeStatus(index, row) {
      this.editForm = row;
      this.statusDialogVisible = true;
    },
    getList() {
      this.listLoading = true;
      this.getRequest('/projectItem/getSubItemList', this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
      });
    },
    handleStatusConfirm() {
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = '/projectItem/updateSubItemStatus/' + this.editForm.id + '?status=' + this.editForm.itemStatus;
        this.postRequest(url).then(resp => {
          this.$message({
            message: '状态更新成功！',
            type: 'success'
          });
          this.statusDialogVisible = false;
          this.getList();
        })
      })
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.editForm.unitPrice && !this.editForm.rentUnitPrice) {
            this.$message({
              message: '请至少输入一种单价！',
              type: 'warning'
            });

            return;
          }

          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const _this = this;
            if (this.albums.length > 0) {
              this.editForm.albumPics = this.albums.join(',');
            }
            let url = '/projectItem/updateSubItem';
            this.postRequest(url, this.editForm).then(resp => {
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            })
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>