<template>
  <div>
    <el-card style="margin-top: 10px;" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
            style="float: right"
            @click="handleSearchList()"
            type="primary"
            size="small">
          查询结果
        </el-button>
        <el-button
            style="float: right;margin-right: 15px"
            @click="handleResetSearch()"
            size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="输入搜索：">
            <el-input style="width: 203px" v-model="listQuery.keyword" placeholder="用户名称"></el-input>
          </el-form-item>
          <el-form-item label="企业服务：">
            <el-select v-model="listQuery.csmId" class="input-width" placeholder="全部" clearable>
              <el-option v-for="(item, index) in csmList"
                         :key="index"
                         :label="item.serviceName"
                         :value="item.id + ''">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="listQuery.status" class="input-width" placeholder="全部" clearable>
              <el-option v-for="(item, index) in itemStatusOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
        返回上级
      </el-button>
      <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>
    </el-card>
    <div class="table-container">
      <el-table ref="itemTable"
                :data="list"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="企业服务" align="center">
          <template slot-scope="scope">{{ scope.row.csmName }}</template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">{{ scope.row.realName }}</template>
        </el-table-column>
        <el-table-column label="电话" align="center">
          <template slot-scope="scope">{{ scope.row.tel }}</template>
        </el-table-column>
        <el-table-column label="所属公司" align="center">
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <el-table-column label="所处职位" align="center">
          <template slot-scope="scope">{{ scope.row.companyTitle || scope.row.companyTitle === 0 ? titleOptions[scope.row.companyTitle] : '' }}</template>
        </el-table-column>
        <el-table-column label="需求资金" align="center">
          <template slot-scope="scope">{{ scope.row.reqAmt ? '￥' + scope.row.reqAmt + '万元' : 'N/A' }}</template>
        </el-table-column>
        <el-table-column label="当前状态" align="center">
          <template slot-scope="scope">{{ itemStatusLabel[scope.row.reqStatus] }}</template>
        </el-table-column>
        <el-table-column label="设置" width="120" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                @click="handleChangeStatus(scope.$index, scope.row)">更改状态
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="text"
                       @click="handleUpdate(scope.$index, scope.row)">
              编辑
            </el-button>
<!--            <el-button size="mini"-->
<!--                       type="text"-->
<!--                       @click="handleDelete(scope.$index, scope.row)">删除-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          :page-sizes="[10,15,20]"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="isEdit ? '编辑需求信息' : '添加需求'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="editItemForm"
               :rules="itemRules"
               label-width="180px" size="small">
        <div class="form-part-section">
        <el-form-item label="所属企业服务" required>
          <el-select v-model="csmIdx" @change="handleCsmChange" class="input-width" placeholder="请选择">
            <el-option v-for="(item, index) in csmList"
                       :key="index"
                       :label="item.serviceName"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="姓名" prop="realName">
            <el-input
                placeholder="请输入姓名"
                v-model.trim="editForm.realName">
            </el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="tel">
            <el-input placeholder="请输入手机号" v-model.trim="editForm.tel"></el-input>
          </el-form-item>
          <el-form-item label="公司名">
            <el-input placeholder="请输入公司名" v-model.trim="editForm.companyName"></el-input>
          </el-form-item>
          <el-form-item label="公司职位">
            <el-select v-model="editForm.companyTitle" class="input-width" placeholder="请选择">
              <el-option v-for="(item, index) in titleOptions"
                         :key="index"
                         :label="item"
                         :value="index">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-part-section" v-if="csmFinanceFlag">
          <el-form-item label="需求资金（万元）" required>
            <el-input type="number" v-model="editForm.reqAmt"></el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="复审部门">
            <el-input placeholder="请输入复审部门" v-model.trim="editForm.relateDept"></el-input>
          </el-form-item>
          <el-form-item label="实际批复资金（万元）" required>
            <el-input type="number" v-model="editForm.actualAmt"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('editItemForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="状态更新"
        :visible.sync="statusDialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="itemStatusForm"
               label-width="150px" size="small">
        <el-form-item label="最新状态:">
          <el-select v-model="editForm.reqStatus" placeholder="请选择">
            <el-option
                v-for="item in itemStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关原因:" v-if="editForm.reqStatus > 10">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入相关原因"
              v-model="editForm.failReason">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="statusDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleStatusConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  keyword: null,
  status: null,
  csmId: null
};
const defaultCsmReq = {  // 企服需求表单
  id: null,
  csmId: null,
  csmName: null,
  realName: '',
  companyName: '',
  companyTitle: null,
  relateDept: null,
  tel: '',
  reqAmt: null,
  failReason: null,
  actualAmt: null,
  percent: null,
  reqStatus: 0
};
export default {
  name: "csmReqList",
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      isEdit: false,
      dialogVisible: false,
      statusDialogVisible: false,
      editForm: Object.assign({}, defaultCsmReq),
      csmList: [],
      csmIdx: 0,
      csmFinanceFlag: false,
      titleOptions: ['企业主', '管理层', '普通员工'],
      itemStatusOptions: [{ // 状态：0-提交表单；1-沟通中；2-提交材料；3-初审材料；4-提交相关部门；5-复审中；9-审核通过；11 - 初审不通过；12 - 复审失败；13 - 已取消
        value: 0,
        label: '提交表单'
      }, {
        value: 1,
        label: '沟通中'
      }, {
        value: 2,
        label: '提交材料'
      }, {
        value: 3,
        label: '初审材料'
      }, {
        value: 4,
        label: '提交相关部门'
      }, {
        value: 5,
        label: '复审中'
      }, {
        value: 9,
        label: '审核通过'
      }, {
        value: 11,
        label: '初审不通过'
      }, {
        value: 12,
        label: '复审失败'
      }, {
        value: 13,
        label: '已取消'
      }],
      itemStatusLabel: {
        13: '已取消',
        12: '复审失败',
        11: '初审不通过',
        0: '提交表单',
        1: '沟通中',
        2: '提交材料',
        3: '初审材料',
        4: '提交相关部门',
        5: '复审中',
        9: '审核通过'
      },
      itemRules: {  //表单校验
        realName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        tel: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.listQuery.csmId = this.$route.query.id;
    this.getCsmList();
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getList();
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
    },
    handleBackToTop() {
      this.$router.back();
    },
    handleAdd() {
      this.isEdit = false;
      this.editForm = Object.assign({}, defaultCsmReq);
      this.dialogVisible = true;
    },
    // handleDelete(index, row) {
    //   this.$confirm('是否要删除该房源?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.deleteRequest('/projectItem/deleteSubItem?id=' + row.id).then(response => {
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       });
    //       this.getList();
    //     });
    //   });
    // },
    handleUpdate(index, row) {
      this.isEdit = true;
      this.editForm = row;
      let _this = this;
      let tmpIndx =this.csmList.findIndex(function (item) {
        // 根据item中的id属性来判断这个item是否是在上面的id中对应的数据，如果是返回true，否返回false，继续下面一条数据的遍历,以此类推
        return item.id === _this.editForm.csmId;
        // 如果返回true,那么findIndex()会在这个item对应的id返回到外面接收
      });
      if (tmpIndx > -1) {
        this.csmFinanceFlag = this.csmList[tmpIndx].financeFlag;
        this.csmIdx = tmpIndx;
      }
      this.dialogVisible = true;
    },
    handleChangeStatus(index, row) {
      this.editForm = row;
      this.statusDialogVisible = true;
    },
    handleCsmChange(idx) {
      let tmpCsm = this.csmList[idx];
      this.editForm.csmId = tmpCsm.id;
      this.editForm.csmName = tmpCsm.serviceName;
      this.csmFinanceFlag = tmpCsm.financeFlag;
    },
    getList() {
      this.listLoading = true;
      this.getRequest('/companyService/getCsReqList', this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
      });
    },
    getCsmList() {
      this.getRequest('/companyService/getAllServices').then(response => {
        let tmpList = response.obj || [];
        this.csmList = [];
        for (let i = 0; i < tmpList.length; i++) {
          let subList = tmpList[i];
          for (let item of subList) {
            this.csmList.push(item);
          }
        }
      });
    },
    handleStatusConfirm() {
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = '/companyService/updateReq';
        this.postRequest(url, this.editForm).then(resp => {
          this.$message({
            message: '状态更新成功！',
            type: 'success'
          });
          this.statusDialogVisible = false;
          this.getList();
        })
      })
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.csmFinanceFlag && !this.editForm.reqAmt) {
            this.$message({
              message: '请输入需求资金！',
              type: 'warning'
            });

            return;
          }

          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const _this = this;
            let tmpIndx =this.csmList.findIndex(function (item) {
              // 根据item中的id属性来判断这个item是否是在上面的id中对应的数据，如果是返回true，否返回false，继续下面一条数据的遍历,以此类推
              return item.id === _this.editForm.csmId;
              // 如果返回true,那么findIndex()会在这个item对应的id返回到外面接收
            });
            if (tmpIndx > -1) {
              this.editForm.csmName = this.csmList[tmpIndx].serviceName;
            }
            let url = '/companyService/updateReq';
            this.postRequest(url, this.editForm).then(resp => {
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
              _this.dialogVisible = false;
              _this.getList();
            })
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>