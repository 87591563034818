<template>
  <div>
    <el-upload
      :action="useOss?ossUploadUrl:minioUploadUrl"
      list-type="picture-card"
      :on-success="handleSuccess"
      :file-list="fileLists"
      :multiple="false"
      :before-upload="beforeUploadVideo"
      :on-progress="handleProgress"
      :data="useOss?dataObj:null"
      :disabled="fileLists.length >= limit || uploadBtn">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <video class="el-upload-list__item-thumbnail" :src="file.url"></video>
        <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handleShowVideo(file)">
                <i class="el-icon-video-play"></i>
            </span>
            <span class="el-upload-list__item-edit" @click="handleEditVideo(file)">
                <i class="el-icon-edit"></i>
            </span>
            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
            </span>
        </span>
        <el-progress type="circle" class="progressModule" :color="colors" :percentage="Number(uploadPercentage)" v-if="showProgress && file.url === uploadUrl"></el-progress>
      </div>
    </el-upload>
    <div>
      <span style="color: #c5c9cf"><span style="color: red;">*</span>只能上传一个mp4/flv/wmv/avi格式的文件，且大小不超过200MB</span>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body width="40%" style="text-align:center">
      <video :src="dialogImageUrl" autoplay class="video" controls="controls"></video>
    </el-dialog>
    <el-dialog :visible.sync="editView" width="40%" append-to-body>
      <el-input type="textarea" :rows="4" v-model="editForm.url" @input="editVideo"></el-input>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'multiUploadVideo',
  props:{
    limit:{
      type:Number,
      default:5,
    },
    fileList:{
      type:Array,
      default: ()=>{
        return []
      }
    },
    data:{
      type:Object,
      default:() =>{
        return {}
      }
    }
  },
  data(){
    return{
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      useOss:true, //使用oss->true;使用MinIO->false
      ossUploadUrl:'https://sybg.oss-cn-shanghai.aliyuncs.com',
      minioUploadUrl:'http://localhost:8080/minio/upload',
      // fileLists:this.fileList,
      // multiVideos: true,
      editForm:{
        url:'',
        name:null
      },
      editIndex: -1,
      editView:false,
      uploadPercentage:0,
      showProgress:false,
      uploadUrl:'',
      colors: [
        {color: '#ADD8E6', percentage: 20},
        {color: '#87CEEB', percentage: 40},
        {color: '#87CEFA', percentage: 60},
        {color: '#00BFFF', percentage: 80},
        {color: '#1296DB', percentage: 100}
      ],
      uploadBtn:false,
    }
  },
  computed: {
    fileLists() {
      let fileList=[];
      for(let i=0;i<this.fileList.length;i++){
        fileList.push({url:this.fileList[i]});
      }
      return fileList;
    }
  },
  methods:{
    // 视频上传前执行
    beforeUploadVideo (file) {
      const inLimitSize = file.size / 1024 / 1024 < 200
      let fileType = file.type;
      let isCorrectType = false;
      if (fileType === '') { // 用文件名后缀作为文件类型
        let fileName = file.name;
        let nameSplits = fileName.split('.');
        if (nameSplits.length > 0) {
          fileType = nameSplits[nameSplits.length - 1];
          if (['mp4', 'ogg', 'flv', 'avi', 'wmv', 'rmvb'].indexOf(fileType) !== -1) {
            isCorrectType = true;
          }
        }
      }
      if (!isCorrectType && ['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(fileType) === -1) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      if (!inLimitSize) {
        this.$message.error('上传视频大小不能超过200MB哦!')
        return false
      }
      let _self = this;
      if(!this.useOss){
        //不使用oss不需要获取策略
        return true;
      }
      return new Promise((resolve, reject) => {
        _self.getRequest('/aliyun/oss/policy').then(response => {
          _self.dataObj.policy = response.obj.policy;
          _self.dataObj.signature = response.obj.signature;
          _self.dataObj.ossaccessKeyId = response.obj.accessKeyId;
          _self.dataObj.key = response.obj.dir + '/${filename}';
          _self.dataObj.dir = response.obj.dir;
          _self.dataObj.host = response.obj.host;
          resolve(true)
        }).catch(err => {
          console.log(err)
          reject(false)
        })
      })
    },
    // 移除视频
    handleRemove(file) {
      for (let i = 0; i < this.fileLists.length; i++) {
        if(this.fileLists[i].url === file.url){
          this.fileLists.splice(i,1)
        }
      }
      this.submitFile()
    },
    handleShowVideo(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传完成
    handleSuccess(response,file,fileList){
      this.showProgress = false
      this.uploadBtn = false
      let url = this.dataObj.host + '/' + this.dataObj.dir + '/' + file.name;
      if(!this.useOss){
        //不使用oss直接获取图片路径
        url = response.data.url;
      }
      this.fileLists.push({url:url});
      this.submitFile()
    },
    // 播放视频
    handleEditVideo(file){
      this.editForm.url = file.url
      this.editForm.name = file.name
      this.editIndex = -1;
      for (let i = 0; i < this.fileLists.length; i++) {
        if(this.fileLists[i].url === this.editForm.url){
          this.editIndex = i;
          break;
        }
      }
      this.editView = true
    },
    // 编辑视频
    editVideo(){
      if (this.editIndex !== -1) {
        this.fileLists[this.editIndex].url = this.editForm.url
      }
      this.submitFile()
    },
    // 将图片文件传回给父组件
    submitFile(){
      this.$emit('submitVideo',this.fileLists)
    },
    // 上传进度
    handleProgress(response,file,fileList){
      this.uploadBtn = true
      this.uploadUrl = file.url
      this.showProgress = true
      this.uploadPercentage = file.percentage.toFixed(0)
    },
  }
}
</script>

<style scoped>
.el-icon-plus{
  font-size: 30px!important;
}
.el-icon-edit{
  font-size: 18px !important;
}
.el-icon-video-play{
  font-size: 18px !important;

}
.el-icon-delete{
  font-size: 18px !important;
  color:rgb(243, 143, 130);
}
.el-input>>> .el-textarea__inner{
  font-size:18px!important;
}
.video{
  min-height: 200px;
  max-height: 600px;
  min-width: 200px;
  max-width: 100%;
}
.progressModule >>> .el-progress__text{
  color:#1296DB;
  font-size: 15px!important;
}
</style>
