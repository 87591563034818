<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 0.1rem;">
      <el-tab-pane label="全部" name="0">
         <!-- 搜索 -->
        <el-input size="small" v-model="input_title" placeholder="请输入标题，可回车搜索..." prefix-icon="el-icon-search"
        style="width: 400px;margin-right: 10px;" @keydown.enter.native="search_title"></el-input>
        <el-button size="small" type="primary"  @click="search_title" icon="el-icon-search">搜索</el-button>
        <el-button size="small" type="primary">高级搜索</el-button>
        <!-- 通用项目列表组件 -->
        <div v-if="!hidden_searchcom">
          <ProjectListCom :tindex="tabindex" :activeType="activeName" v-if="resetComponent"></ProjectListCom>
        </div>
        <div v-else>
          <!-- 搜索专用组件 -->
          <SearchProjectsCom v-if="resetComponent" :projectsData="projectsData"></SearchProjectsCom>
        </div>
      </el-tab-pane>
      <el-tab-pane v-for='(tab,tIndx) in allProjectTypes' :key="tIndx" :name="tab.id + ''" :label='tab.name'>
        <ProjectListCom :tindex="tabindex" :activeType="activeName" v-if="resetComponent"></ProjectListCom>
      </el-tab-pane>
      <el-tab-pane label="已删除项目" name="-1">
        <ProjectListCom :tindex="tabindex" :activeType="activeName" v-if="activeName === '-1' && resetComponent"></ProjectListCom>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  //导入子组件
  import ProjectListCom from '@/components/projects/ProjectListCom'
  import SearchProjectsCom from '@/components/projects/SearchProjectsCom'
export default {
  name: 'AllProjects',
  components:{ ProjectListCom, SearchProjectsCom }, //注册子组件
  data () {
   return {
    activeName: '0',
    input_title: '', //输入框的值
    projectsData: [], //项目数据
    tabindex:0, //选项卡index
    hidden_searchcom: false , //是否隐藏搜索子组件
     allProjectTypes: [], // 所有项目分类
     resetComponent: true,
    }
  },
  mounted() {
    this.initType();
  },
  methods:{
    // 获取标签栏的index
    handleClick(tab, event) {
        this.tabindex = tab.index
      this.rebuildComponents();
    },
    //初始化类别
    initType() {
      const _this = this
      this.getRequest('/type/getAllTypes').then(resp => {
        if (resp.obj) {
          _this.allProjectTypes = resp.obj
        }
      })
    },
    rebuildComponents() {
      this.resetComponent = false;
      // 重新创建子标签
      let _this = this;
      this.$nextTick(() => {
        _this.resetComponent = true;
      });
    },
    //根据标题搜索
    search_title(){
      //判断使用的是通用项目子组件还是搜索专用子组件
      this.hidden_searchcom = this.input_title !== '';
      const _this = this
      //发起根据标题搜索请求
      this.getRequest('/project/getByTitle?title=' + this.input_title).then(resp=>{
        if(resp){
          _this.projectsData = resp.obj
        }
      
      })
    }

  }
}
</script>

<style scoped>

</style>