<template>
  <div>
    <el-card shadow="never">
      <i class="el-icon-tickets"></i>
      <span style="font-size: 24px;">服务列表</span>
      <el-button size="mini" style="float: right;" @click="handleAdd()">添加</el-button>
    </el-card>
    <div>
      <el-table
          :data="list"
          style="width: 100%"
          border>
        <el-table-column
            label="编号"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务图标" width="120" align="center">
          <template slot-scope="scope"><img style="height: 80px" :src="scope.row.serviceIcon ? scope.row.serviceIcon : require('@/assets/logo.png')"></template>
        </el-table-column>
        <el-table-column
            label="服务名" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceName }}
          </template>
        </el-table-column>
        <el-table-column
            label="默认联系人" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceContact }}
          </template>
        </el-table-column>
        <el-table-column
            label="服务热线" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceTel }}
          </template>
        </el-table-column>
        <el-table-column
            label="工作日服务时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceWorkTime }}
          </template>
        </el-table-column>
        <el-table-column
            label="节假日服务时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceHolidayTime ? scope.row.serviceHolidayTime : 'N/A' }}
          </template>
        </el-table-column>
        <el-table-column
            label="服务内容" align="center">
          <template slot-scope="scope">
            {{ scope.row.serviceDesc ? scope.row.serviceDesc : 'N/A' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <p>
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
              <el-button
                  size="mini"
                  @click="viewReqList(scope.row)">需求列表
              </el-button>

            </p>
            <p>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
              <el-button
                  size="mini"
                  type="primary"
                  @click="viewSubList(scope.row)">查看下级
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEdit?'编辑企业服务':'添加企业服务'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="companyService"
               ref="serviceInfoForm"
               :rules="rules"
               label-width="120px" size="small">
        <el-form-item label="服务名" prop="serviceName">
          <el-input v-model="companyService.serviceName" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="默认联系人" prop="serviceContact">
          <el-input v-model="companyService.serviceContact" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="服务热线" prop="serviceTel">
          <el-input v-model="companyService.serviceTel" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="金融产品">
          <el-switch
              v-model="companyService.financeFlag"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="服务内容">
          <el-input
              type="textarea"
              style="width: 350px"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入服务内容"
              v-model="companyService.serviceDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="工作日服务时间" required>
          <el-time-select
              placeholder="起始时间"
              v-model="workStartTime"
              style="margin-right: 10px;"
              :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '23:00'
              }">
          </el-time-select>
          <el-time-select
              placeholder="结束时间"
              v-model="workEndTime"
              :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '23:00',
                minTime: workStartTime
              }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="节假日服务时间">
          <el-time-select
              placeholder="起始时间"
              v-model="restStartTime"
              style="margin-right: 10px;"
              :picker-options="{
                start: '08:00',
                step: '00:15',
                end: '23:00'
              }">
          </el-time-select>
          <el-time-select
              placeholder="结束时间"
              v-model="restEndTime"
              :picker-options="{
                start: '08:00',
                step: '00:15',
                end: '23:00',
                minTime: restStartTime
              }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="服务图标：">
          <single-upload v-model="companyService.serviceIcon"
                         style="width: 300px;display: inline-block;margin-left: 10px"></single-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('serviceInfoForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload';

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  parentId: 0
};
const defaultService = {
  id: null,
  parentId: 0,
  serviceIcon: null,
  serviceName: null,
  serviceContact: null,
  serviceTel: null,
  financeFlag: false,
  serviceDesc: null,
  serviceWorkTime: null,
  serviceHolidayTime: null
};

export default {
  name: 'serviceList',
  components: {SingleUpload},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      companyService: Object.assign({}, defaultService),
      parentId: 0,
      parentName: "公司级",
      list: [],  //分类数据
      total: 0, //总记录数
      isEdit: false,
      dialogVisible: false,
      workStartTime: null,
      workEndTime: null,
      restStartTime: null,
      restEndTime: null,
      rules: {
        serviceName: [{required: true, message: '请输入服务名', trigger: 'blur'}],
        serviceContact: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        serviceTel: [{required: true, message: '请输入服务热线', trigger: 'blur'}],
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.parentId = this.$route.query.id;
    }
    if (this.$route.query.name) {
      this.parentName = this.$route.query.name;
    }
    this.getList();
  },
  methods: {
    //初始化分类数据
    getList() {
      const _this = this
      this.listQuery.parentId = this.parentId;
      this.getRequest('/companyService/getServiceList', this.listQuery).then(resp => {
        if (resp.obj) {
          _this.list = resp.obj.list
          _this.total = resp.obj.total
        }
      })
    },
    handleAdd() {
      this.dialogVisible = true;
      this.isEdit = false;
      this.companyService = Object.assign({}, defaultService);
      this.workStartTime = null;
      this.workEndTime = null;
      this.restStartTime = null;
      this.restEndTime = null;
    },
    //编辑分类
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.companyService = Object.assign({}, row);
      if (this.companyService.serviceWorkTime) {
        let workTimes = this.companyService.serviceWorkTime.split('-');
        this.workStartTime = workTimes[0];
        this.workEndTime = workTimes[1];
      }
      if (this.companyService.serviceHolidayTime) {
        let holidayTimes = this.companyService.serviceHolidayTime.split('-');
        this.restStartTime = holidayTimes[0];
        this.restEndTime = holidayTimes[1];
      }
    },
    //删除分类
    handleDelete(index, row) {
      const _this = this
      this.getRequest('/companyService/countServices/' + row.id).then(resp => {
        if (resp.obj && resp.obj > 0) {
          this.$message({
            type: 'warning',
            message: '该服务包含子服务，请删除子服务后再试！'
          });

          return;
        }
        _this.$confirm('此操作将删除该服务类目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.deleteRequest('/companyService/deleteService?id=' + row.id).then(resp => {
            if (resp) {
              _this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      })
    },
    viewReqList(row) {
      this.$router.push({path: '/admin/csmReqList', query: {id: row.id}});
    },
    viewSubList(row) {
      this.$router.push({path: '/admin/subServiceList', query: {id: row.id, name: row.serviceName}});
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.listQuery.pageNum = val;
      this.getList()
    },
    handleDialogConfirm(formName) {
      if (!this.workStartTime || !this.workEndTime) {
        this.$message({
          message: '请选择工作日服务时间！',
          type: 'warning'
        });

        return;
      }
      if ((!this.restStartTime && this.restEndTime) || (this.restStartTime && !this.restEndTime)) {
        this.$message({
          message: '请完善节假日服务时间！',
          type: 'warning'
        });

        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.companyService.serviceWorkTime = this.workStartTime + '-' + this.workEndTime;
            if (this.restStartTime) {
              this.companyService.serviceHolidayTime = this.restStartTime + '-' + this.restEndTime;
            }
            this.postRequest('/companyService/updateService', this.companyService).then(response => {
              this.$message({
                message: this.isEdit ? '修改成功！' : '添加成功！',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            })
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          });
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>