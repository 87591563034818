<template>
  <div>
    <div>
      <el-autocomplete
        v-model="addressKeyword"
        placeholder="请输入地址来直接查找相关位置"
        @input="mapInput"
        clearable
        style="margin-bottom:20px;width:100%;"
        :fetch-suggestions="querySearch"
        @select="handleSelect"
      >
        <el-button slot="append" icon="el-icon-search" @click="getAddressKeyword"></el-button>
      </el-autocomplete>
    </div>
    <div id="map_container"></div>
  </div>
</template>
<script>
/* eslint-disable */
import TMap from "TMap";
import {jsonp} from 'vue-jsonp';

var markerLayer = null;
export default {
  name: "mapChild",
  props: {
    address: {
      type: String,
      default: ''
    },
    latitude: {
      type: [String, Number],
      default: ''
    },
    longitude: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      zoom: 16,
      buildings: [],
      map: null,
      addressKeyword: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let that = this;
      // if (!this.latitude || this.latitude === '') {
      //   // 根据IP获取默认经纬度
      //   await this.getDefaultLanLng();
      //   // return false;
      // }
      this.$nextTick(() => {
        let center = new TMap.LatLng(this.latitude, this.longitude);
        that.map = new TMap.Map(document.getElementById("map_container"), {
          center: center,
          zoom: this.zoom
        });
        //创建并初始化MultiMarker
        markerLayer = new TMap.MultiMarker({
          map: that.map,  //指定地图容器
          //点标记数据数组
          geometries: [// 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: 'marker',
            }
          ]
        });
        that.map.on("click", function (event) {
          let longitude = event.latLng.getLng().toFixed(6); // 经度
          let latitude = event.latLng.getLat().toFixed(6); // 纬度
          that.$emit('mapSend', {latitude: latitude, longitude: longitude});
          let data = markerLayer.getGeometryById('marker');
          Object.assign(data, {
            position: event.latLng,
          });
          markerLayer.updateGeometries([data]);
          let latLngParam = event.latLng.getLat() + ',' + event.latLng.getLng();
          that.getAddressCode(latLngParam);
        });
      })
    },
    querySearch(queryString, cb) {
      let buildings = this.buildings;
      let results = queryString ? buildings.filter(this.createFilter(queryString)) : buildings;
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (buildings) => {
        return (buildings.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      }
    },
    handleSelect(item) {
      this.getAddressKeyword1(item.address);
    },
    mapInput(e) {
      const that = this;
      jsonp('https://apis.map.qq.com/ws/place/v1/suggestion', {
        output: 'jsonp',
        keyword: e,
        region: '中国',
        key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
      })
        .then(function (response) {
          const arr = response.data;
          if (arr) {
            arr.forEach(item => {
              item.value = item.title
            });
            that.buildings = arr;
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAddress(e) {
      const that = this;
      jsonp('https://apis.map.qq.com/ws/geocoder/v1/', {
        output: 'jsonp',
        address: e,
        key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
      })
        .then(function (response) {
          if (!response.result.location) { // 生产环境有误时不影响保存
            return;
          }
          that.map.setCenter(response.result.location);
          const latLng = new TMap.LatLng(response.result.location.lat, response.result.location.lng);
          that.$emit('mapSend', {
            latitude: response.result.location.lat,
            longitude: response.result.location.lng
          });
          let data = markerLayer.getGeometryById('marker');
          Object.assign(data, {
            position: latLng,
          });
          markerLayer.updateGeometries([data]);
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAddressCode(latLng) {
      const that = this;
      jsonp('https://apis.map.qq.com/ws/geocoder/v1/', {
        output: 'jsonp',
        location: latLng,
        key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
      })
        .then(function (response) {
          let tmpAddr = response.result.formatted_addresses.recommend;
          if (!tmpAddr) {
            tmpAddr = response.result.address;
          }
          that.addressKeyword = tmpAddr;
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // getDefaultLanLng() {
    //   const that = this;
    //   jsonp('https://apis.map.qq.com/ws/location/v1/ip/', {
    //     output: 'jsonp',
    //     key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
    //   })
    //       .then(function (response) {
    //         console.log(response)
    //       })
    //       .catch(function (error) {
    //         console.log(error)
    //       })
    // },
    getAddressKeyword1(e) {
      this.getAddress(e);
    },
    // 通过地址获得位置
    getAddressKeyword() {
      // 通过getLocation();方法获取位置信息值
      this.getAddress(this.addressKeyword);
    },
  }
};
</script>
<style>
#map_container {
  min-width: 600px;
  min-height: 400px;
}
</style>
