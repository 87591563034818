<template>
  <div>
    <el-card style="margin-top: 10px;" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
            style="float: right"
            @click="handleSearchList()"
            type="primary"
            size="small">
          查询结果
        </el-button>
        <el-button
            style="float: right;margin-right: 15px"
            @click="handleResetSearch()"
            size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="输入搜索：">
            <el-input style="width: 203px" v-model="listQuery.keyword" placeholder="部门名称"></el-input>
          </el-form-item>
          <el-form-item label="上级部门：">
            <el-cascader
                clearable
                v-model="selectParentValue"
                :props="{ checkStrictly: true }"
                :options="parentOptions">
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card shadow="never">
      <i class="el-icon-tickets"></i>
      <span style="font-size: 24px;">部门列表</span>
      <el-button size="mini" style="float: right;" @click="handleAdd()" v-if="userInfo.roleType === 1">添加</el-button>
    </el-card>
    <div>
      <el-table
          :data="list"
          style="width: 100%"
          border>
        <el-table-column
            label="编号"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="部门名" align="center">
          <template slot-scope="scope">
            {{ scope.row.depName ? scope.row.depName : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="上级部门" align="center">
          <template slot-scope="scope">
            {{ scope.row.parentName ? scope.row.parentName : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="部门等级">
          <template slot-scope="scope">
            {{ scope.row.depLevel ? scope.row.depLevel : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="部门备注">
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <p v-if="userInfo.roleType === 1">
              <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
              <el-button
                  size="mini"
                  type="warning"
                  @click="assignUser(scope.$index, scope.row)">设置员工
              </el-button>
            </p>
            <p v-if="userInfo.roleType === 1">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
              <el-button
                  size="mini"
                  type="success"
                  @click="viewUsers(scope.$index, scope.row)">查看员工
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEdit?'编辑部门':'添加部门'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="deptInfo"
               ref="deptInfoForm"
               :rules="rules"
               label-width="150px" size="small">
        <el-form-item label="上级部门：">
          <el-cascader
              clearable
              v-model="newParentValue"
              :props="{ checkStrictly: true }"
              :options="parentOptions">
          </el-cascader>
        </el-form-item>
        <el-form-item label="部门名：" prop="depName">
          <el-input v-model="deptInfo.depName" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
              type="textarea"
              style="width: 350px"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入备注"
              v-model="deptInfo.remark">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('deptInfoForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="分配部门员工"
        :visible.sync="dialog_assign"
        width="50%"
    >
      <el-form status-icon label-width="120px">
        <el-form-item label="分配员工：">
          <el-transfer
              ref="myTransfer"
              filterable
              :titles="['待选员工', '已选员工']"
              :filter-method="filterMethod"
              filter-placeholder="请输入关键字查询"
              v-model="selectedUserIds"
              @change="handleTransferChange"
              :data="userData"
              :props="{
            key: 'id',
            label: 'realName',
            disabled: 'customDisabled',
          }"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="部门负责人：">
          <el-select v-model="selLeaderId" class="input-width" placeholder="请选择">
            <el-option v-for="(item, index) in leaderOptions"
                       :key="index"
                       :label="item.realName"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
           <el-button @click="dialog_assign = false">取 消</el-button>
           <el-button type="primary" @click="assignConfirm">确 定</el-button>
         </span>
    </el-dialog>
  </div>
</template>
<script>

import {mapGetters} from "vuex";

const defaultListQuery = {
  keyword: null,
  pageNum: 1,
  pageSize: 10,
  userId: null,
  parentId: null
};
const defaultDeptInfo = {
  id: null,
  parentId: 0,
  depName: null,
  depLevel: 0,
  agentId: 0,
  remark: null,
  createTime: null
};
export default {
  name: "deptList",
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      // user: JSON.parse(window.sessionStorage.getItem("user")),
      deptInfo: Object.assign({}, defaultDeptInfo),
      agentId: null,
      list: null,
      total: null,
      listLoading: true,
      selectParentValue: null,
      newParentValue: [],
      parentOptions: [],
      isEdit: false,
      dialogVisible: false,
      dialog_assign: false,
      selDeptId: null,
      selParentId: null,
      userData: [],  //分类数据
      selectedUserIds: [], // 实际已存在的用户列表
      leaderOptions: [], // 经理人选
      selLeaderId: null,
      rules: {
        depName: [{required: true, message: '请输入部门名', trigger: 'blur'}],
      },
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'userInfo'])
  },
  created() {
    this.getList();
    this.getDeptList();
  },
  watch: {
    selectParentValue: function (newValue) {
      if (newValue != null && newValue.length > 0) {
        this.listQuery.parentId = newValue[newValue.length - 1];
      } else {
        this.listQuery.parentId = null;
      }
    },
    newParentValue: function (newValue) {
      console.log(newValue)
      if (newValue != null && newValue.length > 0) {
        this.selParentId = newValue[newValue.length - 1];
      } else {
        this.selParentId = null;
      }
    }
  },
  methods: {
    getDeptList() {
      let param = {
        userId: this.userInfo.id,
        userType: this.userInfo.userType,
        agentId: null
      }
      this.getRequest('/dept/deptTree', param).then(resp => {
        let deptInfo = resp.obj;
        this.agentId = deptInfo.agentId || null;
        let list = deptInfo.depts || [];
        this.parentOptions = [];
        for (let i = 0; i < list.length; i++) {
          let children = [];
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({label: list[i].children[j].depName, value: list[i].children[j].id});
            }
          }

          this.parentOptions.push({label: list[i].depName, value: list[i].id, children: children});
        }
      })
    },
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleResetSearch() {
      this.selectParentValue = [];
      this.listQuery = Object.assign({}, defaultListQuery);
    },
    handleAdd() {
      this.dialogVisible = true;
      this.isEdit = false;
      this.newParentValue = [];
      this.selParentId = null;
      this.deptInfo = Object.assign({}, defaultDeptInfo);
    },
    //编辑
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.selParentId = row.parentId;
      this.newParentValue = [];
      this.newParentValue.push(this.selParentId);

      this.deptInfo = Object.assign({}, row);
    },
    handleDelete(index, row) {
      const _this = this
      this.$confirm('此操作将删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/dept/deleteDept?id=' + row.id).then(resp => {
          if (resp.obj) {
            let count = resp.obj;
            if (count === -1) {
              this.$message({
                type: 'warning',
                message: '该部门还有员工，请先移除部门员工！'
              });
              return;
            }
            if (count === 0) {
              this.$message({
                type: 'warning',
                message: '该部门有直属部门或删除失败，需先删除直属部门！'
              });
              return;
            }
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    assignUser(index, row) {
      this.selDeptId = row.id;
      if (this.userData.length === 0) { // 用户初始数据未获取过
        this.getRequest('/dept/companyUsers', {deptId: this.selDeptId}).then(resp => {
          this.userData = resp.obj;
          this.userData.forEach((item) => {
            item.customDisabled = false;
          });

          this.initDeptUsers();
          this.dialog_assign = true;
        })
      } else {
        this.initDeptUsers();
        this.dialog_assign = true;
      }
    },
    initDeptUsers() {
      this.getRequest('/dept/deptUsers', {deptId: this.selDeptId}).then(resp => {
        let list = resp.obj;
        this.selectedUserIds = list.map((user) => user.id);
      })
    },
    filterMethod(query, item) {
      return item.realName.indexOf(query) > -1;
    },
    handleTransferChange(value, direction, movedKeys) {
      this.leaderOptions = [];
      this.selLeaderId = null;
      for (let key of value) {
        let tmpIndx =this.userData.findIndex(function (item) {
          // 根据item中的id属性来判断这个item是否是在上面的id中对应的数据，如果是返回true，否返回false，继续下面一条数据的遍历,以此类推
          return item.id === key;
          // 如果返回true,那么findIndex()会在这个item对应的id返回到外面接收
        });
        if (tmpIndx > -1) {
          this.leaderOptions.push(this.userData[tmpIndx]);
        }
      }
    },
    assignConfirm() {
      if (!this.selLeaderId && this.selectedUserIds.length > 0) {
        this.$message({
          type: 'warning',
          message: '请选择部门负责人！'
        });
        return;
      }
      let param = {
        deptId: this.selDeptId,
        leaderId: this.selLeaderId,
        userIds: this.selectedUserIds
      }

      const _this = this
      this.postRequest('/dept/assignDeptUsers', param).then(resp => {
        _this.selLeaderId = null
        _this.selectedUserIds = []
        _this.dialog_assign = false
      })
    },
    handleDialogConfirm() {
      if (!this.selParentId && this.userInfo.roleType !== 1) { // 管理员可创建最高级部门
        this.$message({
          message: '请选择上级部门！',
          type: 'warning'
        });
        return false;
      }
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (!this.deptInfo.agentId) {
          this.deptInfo.agentId = this.agentId;
        }
        this.deptInfo.parentId = this.selParentId;
        this.postRequest('/dept/addOrUpdateDept', this.deptInfo).then(resp => {
          this.dialogVisible = false;
          this.getList();
        })
      })
    },
    getList() {
      const _this = this
      this.listQuery.userId = this.userInfo.id;
      this.getRequest('/dept/deptList', this.listQuery).then(resp => {
        if (resp.obj) {
          _this.list = resp.obj.list
          _this.total = resp.obj.total
        }
      })
    },
    viewUsers(index, row) {
      this.$router.push({path:'/admin/deptMembers',query:{id:row.id, name: row.depName}});
    }
  }
}
</script>
<style scoped>

</style>