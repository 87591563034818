<template>
<div>
  <el-card class="operate-container" shadow="never">
    <i class="el-icon-tickets"></i>
    <span>数据列表</span>
    <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
      返回上级
    </el-button>
    <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>
  </el-card>
  <div class="table-container">
    <el-table ref="itemTable"
              :data="list"
              style="width: 100%;"
              v-loading="listLoading" border>
      <el-table-column label="编号" width="100" align="center">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="标题" align="center">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column label="户型" align="center">
        <template slot-scope="scope">{{ scope.row.houseLayout }}</template>
      </el-table-column>
      <el-table-column label="房屋类型" align="center">
        <template slot-scope="scope">{{ houseTypeLabel[scope.row.houseType] }}</template>
      </el-table-column>
      <el-table-column label="建筑面积（㎡）" align="center">
        <template slot-scope="scope">{{ scope.row.floorBuildArea }}</template>
      </el-table-column>
      <el-table-column label="实用面积（㎡）" align="center">
        <template slot-scope="scope">{{ scope.row.floorUseArea }}</template>
      </el-table-column>
<!--      <el-table-column label="装修情况" align="center">-->
<!--        <template slot-scope="scope">{{ decorateTypeLabel[scope.row.decorateType] }}</template>-->
<!--      </el-table-column>-->
      <el-table-column label="单价" align="center">
        <template slot-scope="scope">{{ scope.row.houseType === 0 ? (scope.row.rentUnitPrice + '元/㎡/天') : (scope.row.unitPrice + '元/㎡') }}</template>
      </el-table-column>
      <el-table-column label="总价" align="center">
        <template slot-scope="scope">{{ scope.row.totalPrice + (scope.row.houseType === 0 ? '元/月' : '元') }}</template>
      </el-table-column>
      <el-table-column label="当前状态" align="center">
        <template slot-scope="scope">{{ itemStatusLabel[scope.row.itemStatus] }}</template>
      </el-table-column>
      <el-table-column label="设置" width="120" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              @click="handleChangeStatus(scope.$index, scope.row)">更改状态
          </el-button>
        </template>
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--              size="mini"-->
<!--              type="text"-->
<!--              @click="handleShowNextLevel(scope.$index, scope.row)">查看具体房源-->
<!--          </el-button>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     @click="handleUpdate(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button size="mini"
                     type="text"
                     @click="handleDelete(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagination-container">
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        :page-sizes="[10,15,20]"
        :total="total">
    </el-pagination>
  </div>
  <el-dialog
      title="房源状态更新"
      :visible.sync="statusDialogVisible"
      width="40%">
    <el-form ref="itemStatusForm"
             label-width="150px" size="small">
      <el-form-item label="最新状态:">
        <el-select v-model="selItemStatus" placeholder="请选择">
          <el-option
              v-for="item in itemStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="statusDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleStatusConfirm()" size="small">确 定</el-button>
      </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "projectItemList",
  data() {
    return {
      listQuery: {
        projectId: null,
        pageNum: 1,
        pageSize: 10
      },
      projectId: null,
      list: null,
      total: null,
      listLoading: false,
      statusDialogVisible: false,
      itemId: null,
      selItemStatus: null,
      houseTypeLabel: {
        0: '租赁',
        1: '售卖'
      },
      itemStatusOptions: [{ // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
        value: 0,
        label: '待售'
      }, {
        value: 1,
        label: '意向订'
      }, {
        value: 2,
        label: '已预付'
      }, {
        value: 3,
        label: '已定'
      }, {
        value: 4,
        label: '已交接完成'
      }, {
        value: 9,
        label: '已下架'
      }],
      itemStatusLabel: { // 0-待售；1-意向订；2-已预付；3-已定；4-已交接完成；9-已下架
        0: '待售',
        1: '意向订',
        2: '已预付',
        3: '已定',
        4: '已交接完成',
        9: '已下架'
      },
      decorateTypeLabel: {
        0: '毛坯',
        1: '简装',
        2: '精装',
        3: '拎包入驻'
      }
    }
  },
  created() {
    this.listQuery.projectId = this.$route.query.id;
    this.projectId = this.$route.query.id;
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleBackToTop() {
      this.$router.back();
    },
    handleAdd() {
      this.$router.push({path: '/admin/editProjectItem', query: {projectId: this.projectId}});
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该户型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/projectItem/deleteItem?id=' + row.id).then(response => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        });
      });
    },
    handleUpdate(index, row) {
      this.$router.push({path: '/admin/editProjectItem', query: {id: row.id, projectId: this.projectId}});
    },
    handleChangeStatus(index, row) {
      this.itemId = row.id;
      this.selItemStatus = row.itemStatus;
      this.statusDialogVisible = true;
    },
    handleStatusConfirm() {
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = '/projectItem/updateSubItemStatus/' + this.itemId + '?status=' + this.selItemStatus;
        this.postRequest(url).then(resp => {
          this.$message({
            message: '状态更新成功！',
            type: 'success'
          });
          this.statusDialogVisible = false;
          this.getList();
        })
      })
    },
    // handleShowNextLevel(index, row) {
    //   this.$router.push({path: '/admin/subItems', query: {id: row.id, projectId: this.projectId}});
    // },
    getList() {
      this.listLoading = true;
      this.getRequest('/projectItem/getItemList', this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
      });
    },
  }
}
</script>

<style scoped>

</style>