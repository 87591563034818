<template>
  <div class="m_container">
    <!-- 基本内容 -->
    <div class="m_content">
      <el-form ref="editForm" status-icon :model="editForm" :rules="rules" label-width="180px">
        <div class="form-part-section">
          <el-form-item label="企业名称" prop="orgName">
            <el-input v-model="editForm.orgName"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="editForm.orgTel"></el-input>
          </el-form-item>
          <el-form-item label="座机">
            <el-input v-model="editForm.orgLandline"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="editForm.orgEmail"></el-input>
          </el-form-item>
          <el-form-item label="QQ号">
            <el-input v-model="editForm.orgQq"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名">
            <el-input v-model="editForm.orgContact" placeholder="请输入企业联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系人企微">
            <single-upload ref="weixinQr" v-model="editForm.orgWeixin"></single-upload>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="企业LOGO">
            <single-upload ref="orgLogo" v-model="editForm.orgLogo"></single-upload>
          </el-form-item>
          <el-form-item label="企业简介" prop="orgDesc">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入企业简介"
                v-model="editForm.orgDesc">
            </el-input>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="企业公众号">
            <single-upload ref="gzQr" v-model="editForm.orgWxGz"></single-upload>
          </el-form-item>
          <el-form-item label="企业抖音号">
            <single-upload ref="dyQr" v-model="editForm.orgDouyin"></single-upload>
          </el-form-item>
        </div>
        <div class="form-part-section">
          <el-form-item label="所在区域" required>
            <v-region v-model="selectedAreas" @values="regionChange"></v-region>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="editForm.detailAddr"></el-input>
          </el-form-item>
          <el-form-item label="地图定位">
          <span v-show="editForm.longitude"
                style="margin-right: 10px;">经度：{{ editForm.longitude }}, 纬度：{{ editForm.latitude }}</span>
            <el-button size="mini" type="primary" @click="searchMapAddr">定位</el-button>
          </el-form-item>
          <el-form-item label="位置地图截图">
            <single-upload ref="locationImg" v-model="editForm.orgLocationImg"></single-upload>
          </el-form-item>
        </div>

        <el-form-item style="margin: 60px; text-align: center;">
          <el-button type="primary" @click="toSubmitForm('editForm', false)">保  存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-dialog
          title="请标记地图定位"
          :visible.sync="mapDialogVisible"
          width="60%">
        <map-child :address="editForm.detailAddr" :latitude="mapLat" :longitude="mapLng" v-if="isResetComponent"
                   @mapSend="addrMark"></map-child>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleMapCancel()" size="small">取 消</el-button>
        <el-button type="primary" @click="mapDialogVisible = false" size="small">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import mapChild from '@/components/qqMap/map'
import {jsonp} from "vue-jsonp";

const defaultOrg = {  //表单
  id: null,
  orgName: '',
  orgTel: '',
  orgLandline: '',
  orgDesc: '',
  orgLogo: '',
  orgContact: '',
  orgEmail: '',
  orgQq: '',
  orgWeixin: '',
  orgWxGz: '',
  orgDouyin: '',
  orgLocationImg: '',
  areaCodes: '',
  areaName: '',
  detailAddr: '',
  latitude: '',
  longitude: ''
};

export default {
  name: "orgDetail",
  components: {SingleUpload, mapChild},
  created() {
    this.initOrg();
  },
  computed: {},
  data() {
    return {
      editForm: Object.assign({}, defaultOrg),
      selectedAreas: {
        province: '',
        city: '',
        area: '',
        town: ''
      },
      mapLat: '',
      mapLng: '',
      oldLatLng: {
        lat: '',
        lng: ''
      },
      mapDialogVisible: false,
      isResetComponent: true,
      rules: {  //表单校验
        orgName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        orgDesc: [
          {required: true, message: '请输入企业简介', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    //初始化数据
    initOrg() {
        const _this = this
        this.getRequest('/org/getOrgDetail').then(resp => {
          if (!resp.obj) {
            return;
          }
          _this.editForm = resp.obj;
          if (resp.obj.areaCodes && resp.obj.areaCodes !== '') {
            let tmpAreas = resp.obj.areaCodes.split(',');
            for (let i = 0; i < tmpAreas.length; i++) {
              if (i === 0) {
                _this.selectedAreas.province = tmpAreas[i];
              } else if (i === 1) {
                _this.selectedAreas.city = tmpAreas[i];
              } else if (i === 2) {
                _this.selectedAreas.area = tmpAreas[i];
              } else {
                _this.selectedAreas.town = tmpAreas[i];
              }
            }
          }

          _this.oldLatLng.lat = _this.editForm.latitude;
          _this.oldLatLng.lng = _this.editForm.longitude;
          if (_this.editForm.latitude && _this.editForm.latitude !== '') {
            _this.mapLat = _this.editForm.latitude;
            _this.mapLng = _this.editForm.longitude;
            _this.rebuildComponents();
          } else {
            // 根据IP获取默认经纬度
            _this.getDefaultLanLng();
          }
        })
    },
    getDefaultLanLng() {
      const that = this;
      jsonp('https://apis.map.qq.com/ws/location/v1/ip/', {
        output: 'jsonp',
        key: 'DBQBZ-ITTCD-MFO4M-PIIT2-PCSTT-WOBWW'
      })
          .then(function (response) {
            // console.log(response)
            if (response.result.location.lat) {
              that.mapLat = response.result.location.lat;
              that.mapLng = response.result.location.lng;
            }
            that.rebuildComponents();
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    regionChange(datas) {
      let tmpCities = '';
      if (datas) {
        if (datas.province) {
          tmpCities = datas.province.value;
        }
        if (datas.city) {
          tmpCities = tmpCities + datas.city.value;
        }
        if (datas.area) {
          tmpCities = tmpCities + datas.area.value;
        }
        if (datas.town) {
          tmpCities = tmpCities + datas.town.value;
        }
      }
      this.editForm.areaName = tmpCities;
    },
    rebuildComponents() {
      this.isResetComponent = false;
      // 重新创建子标签
      let _this = this;
      this.$nextTick(() => {
        _this.isResetComponent = true;
      });
    },
    searchMapAddr() {
      this.mapDialogVisible = true;
    },
    addrMark(params) {
      this.editForm.latitude = params.latitude;
      this.editForm.longitude = params.longitude;
    },
    handleMapCancel() {
      this.editForm.latitude = this.oldLatLng.lat;
      this.editForm.longitude = this.oldLatLng.lng;
      this.mapDialogVisible = false;
    },
    //校验项目基本内容表单
    toSubmitForm(formName, isTempSave) {
      if (!this.selectedAreas.area || this.selectedAreas.area === '') {
        this.$message({
          type: 'warning',
          message: '请选择所在区域！'
        });

        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let tmpAreas = [];
          if (this.selectedAreas.province && this.selectedAreas.province !== '') {
            tmpAreas.push(this.selectedAreas.province);
          }
          if (this.selectedAreas.city && this.selectedAreas.city !== '') {
            tmpAreas.push(this.selectedAreas.city);
          }
          if (this.selectedAreas.area && this.selectedAreas.area !== '') {
            tmpAreas.push(this.selectedAreas.area);
          }
          if (this.selectedAreas.town && this.selectedAreas.town !== '') {
            tmpAreas.push(this.selectedAreas.town);
          }
          this.editForm.areaCodes = tmpAreas.join(',');
          this.postRequest('/org/updateInfo', this.editForm).then(resp => {
            if (resp) {
              this.$message({
                type: 'success',
                message: '保存成功！'
              });
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang='scss'>
.m_container {
  margin-top: 20px;
}

.editor {
  line-height: normal !important;
  height: 500px;
}
</style>