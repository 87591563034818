<template>
  <div>
    <el-card style="margin-top: 10px;" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button
            style="float: right"
            @click="handleSearchList()"
            type="primary"
            size="small">
          查询结果
        </el-button>
        <el-button
            style="float: right;margin-right: 15px"
            @click="handleResetSearch()"
            size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="输入搜索：">
            <el-input style="width: 203px" v-model="listQuery.nameKeyword" placeholder="用户名称或手机号"></el-input>
          </el-form-item>
          <el-form-item label="用户类别：">
            <el-select v-model="listQuery.typeId" class="input-width" placeholder="全部" clearable>
              <el-option v-for="(item, index) in typeList"
                         :key="index"
                         :label="item"
                         :value="index">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card shadow="never">
      <i class="el-icon-tickets"></i>
      <span style="font-size: 24px;">用户列表</span>
      <el-button size="mini" style="float: right;" @click="handleAdd()">添加</el-button>
    </el-card>
    <div>
      <el-table
          :data="userData"
          style="width: 100%"
          border>
        <el-table-column
            label="编号"
            width="50" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户头像" width="120" align="center">
          <template slot-scope="scope"><img style="height: 80px" :src="scope.row.avatar ? scope.row.avatar : require('@/assets/avatar.jpg')"></template>
        </el-table-column>
        <el-table-column
            label="真实姓名" align="center">
          <template slot-scope="scope">
            {{ scope.row.realName ? scope.row.realName : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="昵称" align="center">
          <template slot-scope="scope">
            {{ scope.row.nickname ? scope.row.nickname : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="手机号" align="center">
          <template slot-scope="scope">
            {{ scope.row.tel ? scope.row.tel : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="邮箱" align="center">
          <template slot-scope="scope">
            {{ scope.row.email ? scope.row.email : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="性别" align="center">
          <template slot-scope="scope">
            {{ scope.row.sex !== null ? sexList[scope.row.sex] : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="生日" align="center">
          <template slot-scope="scope">
            {{ scope.row.birthday ? scope.row.birthday : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="所属公司" align="center">
          <template slot-scope="scope">
            {{ scope.row.company ? scope.row.company : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="职务">
          <template slot-scope="scope">
            {{ scope.row.deptTitle ? scope.row.deptTitle : '--' }}
          </template>
        </el-table-column>
        <el-table-column
            label="用户类型" align="center">
          <template slot-scope="scope">
            {{ typeList[scope.row.userType ? scope.row.userType : 0] }}
          </template>
        </el-table-column>
        <el-table-column
            label="邀请人" align="center">
          <template slot-scope="scope">
            <el-button plain type="text" @click="viewInviter(scope.row.inviteUser)">
              {{ scope.row.inviterName ? scope.row.inviterName : (scope.row.inviteUser ? '查看邀请人' : '自行登记') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="审核状态" align="center">
          <template slot-scope="scope">
            {{ auditList[scope.row.auditStatus ? scope.row.auditStatus : 0] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <p>
              <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
              <el-button
                  size="mini"
                  type="warning"
                  @click="resetPwd(scope.$index, scope.row)">重置密码
              </el-button>
            </p>
            <p>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
              <el-button
                  size="mini"
                  :disabled="scope.row.auditStatus > 0"
                  @click="handleAudit(scope.$index, scope.row)">审核
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;" v-if="showPage">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEdit?'编辑用户':'添加用户'"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="userInfo"
               ref="userInfoForm"
               :rules="rules"
               label-width="150px" size="small">
        <el-form-item label="用户类别：">
          <el-select v-model="userInfo.userType" class="input-width" placeholder="请选择">
            <el-option v-for="(item, index) in typeList"
                       :key="index"
                       :label="item"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="真实姓名：" prop="realName">
          <el-input v-model="userInfo.realName" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
          <el-input v-model="userInfo.nickname" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="tel">
          <el-input v-model="userInfo.tel" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="E-mail：" prop="email">
          <el-input v-model="userInfo.email" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select v-model="userInfo.sex" class="input-width" placeholder="请选择">
            <el-option v-for="(item, index) in sexList"
                       :key="index"
                       :label="item"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日：">
          <el-date-picker
              v-model="userInfo.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              :disabled-date="disabledDate"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <el-input v-model="userInfo.company" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="所属部门：">
          <el-input v-model="userInfo.dept" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="职务：">
          <el-input v-model="userInfo.deptTitle" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="个人头像：">
          <single-upload v-model="userInfo.avatar"
                         style="width: 300px;display: inline-block;margin-left: 10px"></single-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('userInfoForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 对话框 设置新密码 -->
    <el-dialog
        title="设置新密码"
        :visible.sync="dialog_pwd"
        width="30%"
    >
      <el-form status-icon label-width="120px">
        <el-form-item label="新密码">
          <el-input v-model="newPwd" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
           <el-button @click="dialog_pwd = false">取 消</el-button>
           <el-button type="primary" @click="resetNewPwd">确 定</el-button>
         </span>
    </el-dialog>
    <el-dialog
        title="审核经纪人申请"
        :visible.sync="statusDialogVisible"
        width="40%">
      <el-form :model="userInfo"
               ref="itemStatusForm"
               label-width="150px" size="small">
        <el-form-item label="审核结果:" required>
          <el-select v-model="userInfo.auditStatus" placeholder="请选择">
            <el-option
                v-for="(item, index) in auditList"
                :key="index"
                :label="item"
                :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入审核意见"
              v-model="userInfo.suggestion">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="statusDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleStatusConfirm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import {isEmail, isPhone} from '@/utils/validate';

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  nameKeyword: null,
  typeId: null
};
const defaultUserInfo = {
  id: null,
  avatar: null,
  email: null,
  nickname: null,
  realName: null,
  markStatus: 0,
  sex: null,
  birthday: null,
  tel: null,
  company: null,
  dept: null,
  deptTitle: null,
  userType: 0,
  auditStatus: 1,
  suggestion: null,
  inviteUser: null,
  inviterName: null,
  createTime: null
};

export default {
  name: 'userList',
  components: {SingleUpload},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('请输入正确的用户邮箱'))
      } else {
        callback()
      }
    };
    const validateTel = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    };
    return {
      listQuery: Object.assign({}, defaultListQuery),
      userInfo: Object.assign({}, defaultUserInfo),
      typeList: ['普通用户', '长武人员', '经纪人'], // 用户类型：0-普通用户；1-员工；2-经纪人；3-管理层
      auditList: ['待审核', '已通过', '已拒绝'],
      sexList: ['男', '女'], // 性别：0-男；1-女
      // selBirthday: '',
      userData: [],  //分类数据
      total: 0, //总记录数
      isEdit: false,
      dialogVisible: false,
      dialog_pwd: false,
      statusDialogVisible: false,
      existUsers: [], // 实际已存在的用户列表
      selUserId: '',
      newPwd: '',
      disabledDate(time) {
        return time.getTime() > Date.now()
      },
      rules: {
        realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        email: [{required: true, trigger: 'blur', validator: validateEmail}],
        tel: [{required: true, trigger: 'blur', validator: validateTel}],
      },
      showPage: true, //是否显示分页
    }
  },
  mounted() {
    this.initUserList();
  },
  methods: {
    //初始化用户数据
    initUserList() {
      const _this = this
      this.getRequest('/user/getByPage', this.listQuery).then(resp => {
        _this.userData = resp.obj.records
        _this.total = resp.obj.total
      })
    },
    handleAdd() {
      this.dialogVisible = true;
      this.isEdit = false;
      this.userInfo = Object.assign({}, defaultUserInfo);
    },
    //编辑用户
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.isEdit = true;
      this.userInfo = Object.assign({}, row);
    },
    //删除用户
    handleDelete(index, row) {
      console.log(index, row);
      const _this = this
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/user/deleteUser?id=' + row.id).then(resp => {
          if (resp) {
            this.initUserList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    handleAudit(index, row) {
      this.userInfo = Object.assign({}, row);
      this.statusDialogVisible = true;
    },
    handleStatusConfirm() {
      if (!this.userInfo.auditStatus) {
        this.$message({
          type: 'warning',
          message: '请选择审核结果！'
        });
        return;
      }
      if (this.userInfo.auditStatus === 2 && !this.userInfo.suggestion) {
        this.$message({
          type: 'warning',
          message: '请输入审核意见！'
        });
        return;
      }
      this.$confirm('是否要确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest('/user/updateUser', this.userInfo).then(resp => {
          this.$message({
            message: '状态更新成功！',
            type: 'success'
          });
          this.statusDialogVisible = false;
          this.initUserList();
        })
      })
    },
    resetPwd(index, row) {
      this.selUserId = row.id;
      this.dialog_pwd = true;
    },
    resetNewPwd() {
      if (!this.newPwd) {
        this.$message({
          type: 'warning',
          message: '请输入新密码！'
        });
        return;
      }
      if (this.newPwd.length < 3) {
        this.$message({
          type: 'warning',
          message: '新密码至少3位字符！'
        });
        return;
      }
      let param = {
        userId: this.selUserId,
        newPwd: this.newPwd
      }
      const _this = this
      this.postRequest('/user/resetPwd', param).then(resp => {
        _this.selUserId = ''
        _this.newPwd = ''
        _this.dialog_pwd = false
      })
    },
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.initUserList();
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.listQuery.pageSize = val;
      this.initUserList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.listQuery.pageNum = val;
      this.initUserList()
    },
    viewInviter(userId) {
      if (!userId) {
        this.$message({
          message: '无邀请人！',
          type: 'success'
        });
        return;
      }
      this.getRequest('/user/getUserById/' + userId).then(resp => {
        let userData = resp.obj;
        let alertMsg = '暂未查到邀请人信息！';
        if (userData && userData.id) {
          alertMsg = '该用户邀请人为：' + userData.realName + '(' + userData.tel + ')';
        }
        this.$message({
          message: alertMsg,
          type: 'success'
        });
      })
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let param = {
              tel: this.userInfo.tel,
              email: this.userInfo.email,
              selfId: this.userInfo.id
            }
            const _this = this;
            this.getRequest('/user/findUserByTel', param).then(resp => {
              _this.existUsers = resp.obj || [];
              if(_this.existUsers.length > 0) {
                let firstUser = _this.existUsers[0];
                if (firstUser.userType !== 0) {
                  let userName = firstUser.realName || '';
                  if(firstUser.tel) {
                    userName = userName + ' ' + firstUser.tel;
                  }
                  if (firstUser.email) {
                    userName = userName + ' ' + firstUser.email;
                  }

                  this.$message({
                    message: '手机号或Email有重复，请核实后再提交！' + (userName ? '可能重复用户为：' + userName : ''),
                    type: 'warning'
                  });

                  return;
                }

                if (!_this.userInfo.id) {
                  _this.userInfo.id = firstUser.id;
                  _this.isEdit = true;
                }
                if (!_this.userInfo.company) {
                  _this.userInfo.company = firstUser.company || '';
                }
                if (!_this.userInfo.dept) {
                  _this.userInfo.dept = firstUser.dept || '';
                }
                if (!_this.userInfo.deptTitle) {
                  _this.userInfo.deptTitle = firstUser.deptTitle || '';
                }
              }

              if (_this.isEdit) {
                _this.postRequest('/user/updateUser', _this.userInfo).then(response => {
                  let tmpPwd = response.obj || null;
                  _this.$message({
                    message: '修改成功！' + (tmpPwd ? '登录密码为：' + tmpPwd : ""),
                    type: 'success'
                  });
                  _this.dialogVisible = false;
                  _this.initUserList();
                })
              } else {
                _this.postRequest('/user/saveUser', _this.userInfo).then(response => {
                  let msg = '添加成功！';
                  let tmpPwd = response.obj || null;
                  if (_this.userInfo.userType !== 0 && tmpPwd) {
                    msg += '初始密码为 ' + tmpPwd;
                  }
                  _this.$message({
                    message: msg,
                    type: 'success'
                  });
                  _this.dialogVisible = false;
                  _this.initUserList();
                })
              }
            })
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          });
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>