<template> 
  <div>
    <el-upload
      :action="useOss?ossUploadUrl:minioUploadUrl"
      :data="useOss?dataObj:null"
      list-type="picture"
      :multiple="false" :show-file-list="showFileList"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip" style="color: red; font-weight: bold;">只能上传jpg/png文件，且不超过10MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" alt="">
    </el-dialog>
  </div>
</template>
<script>

  export default {
    name: 'singleUpload',
    props: {
      value: String
    },
    computed: {
      imageUrl() {
        return this.value;
      },
      imageName() {
        if (this.value != null && this.value !== '') {
          return this.value.substr(this.value.lastIndexOf("/") + 1);
        } else {
          return null;
        }
      },
      fileList() {
        return [{
          name: this.imageName,
          url: this.imageUrl
        }]
      },
      showFileList: {
        get: function () {
          return this.value !== null && this.value !== ''&& this.value!==undefined;
        },
        set: function (newValue) {
        }
      }
    },
    data() {
      return {
        dataObj: {
          policy: '',
          signature: '',
          key: '',
          ossaccessKeyId: '',
          dir: '',
          host: '',
          // callback:'',
        },
        dialogVisible: false,
        useOss:true, //使用oss->true;使用MinIO->false
        ossUploadUrl:'https://sybg.oss-cn-shanghai.aliyuncs.com',
        minioUploadUrl:'http://localhost:8080/minio/upload',
      };
    },
    methods: {
      emitInput(val) {
        this.$emit('input', val)
      },
      handleRemove(file, fileList) {
        this.emitInput('');
      },
      handlePreview(file) {
        this.dialogVisible = true;
      },
      beforeUpload(file) {
        const inLimitSize = file.size / 1024 / 1024 < 10
        let fileType = file.type;
        let isCorrectType = false;
        if (fileType === '') { // 用文件名后缀作为文件类型
          let fileName = file.name;
          let nameSplits = fileName.split('.');
          if (nameSplits.length > 0) {
            fileType = nameSplits[nameSplits.length - 1];
            if (['jpg', 'png', 'jpeg'].indexOf(fileType) !== -1) {
              isCorrectType = true;
            }
          }
        }
        // 上传图片时:
        // ie会把 jpg、jpeg翻译成image/pjpeg，png翻译成image/x-png。
        // 而火狐则很标准：jpg、jpeg翻译成image/jpeg，png翻译成image/png。
        if (!isCorrectType && ['image/jpeg','image/jpg','image/pjpeg','image/x-png','image/png'].indexOf(fileType) === -1) {
          this.$message.error('请上传正确的图片格式')
          return false
        }
        if (!inLimitSize) {
          this.$message.error('上传图片大小不能超过10MB哦!')
          return false
        }
        let _self = this;
        if(!this.useOss){
          //不使用oss不需要获取策略
          return true;
        }
        return new Promise((resolve, reject) => {
          _self.getRequest('/aliyun/oss/policy').then(response => {
            _self.dataObj.policy = response.obj.policy;
            _self.dataObj.signature = response.obj.signature;
            _self.dataObj.ossaccessKeyId = response.obj.accessKeyId;
            _self.dataObj.key = response.obj.dir + '/${filename}';
            _self.dataObj.dir = response.obj.dir;
            _self.dataObj.host = response.obj.host;
            // _self.dataObj.callback = response.data.callback;
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(false)
          })
        })
      },
      handleUploadSuccess(res, file) {
        this.showFileList = true;
        this.fileList.pop();
        let url = this.dataObj.host + '/' + this.dataObj.dir + '/' + file.name;
        if(!this.useOss){
          //不使用oss直接获取图片路径
          url = res.data.url;
        }
        this.fileList.push({name: file.name, url: url});
        this.emitInput(this.fileList[0].url);
      }
    }
  }
</script>
<style>

</style>


