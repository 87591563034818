<template>
  <div>
      <el-form :model="user"
               ref="userInfoForm"
               :rules="rules"
               label-width="150px" size="small" style="margin-top: 20px;">
        <el-form-item label="用户类别：">
          <el-select v-model="user.userType" class="input-width" disabled>
            <el-option v-for="(item, index) in typeList"
                       :key="index"
                       :label="item"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="真实姓名：">
          <el-input v-model="user.realName" disabled style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
          <el-input v-model="user.nickname" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="tel">
          <el-input v-model="user.tel" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="E-mail：" prop="email">
          <el-input v-model="user.email" disabled style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-select v-model="user.sex" class="input-width" placeholder="请选择">
            <el-option v-for="(item, index) in sexList"
                       :key="index"
                       :label="item"
                       :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日：">
          <el-date-picker
              v-model="user.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              :disabled-date="disabledDate"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <el-input v-model="user.company" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="所属部门：">
          <el-input v-model="user.dept" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="职务：">
          <el-input v-model="user.deptTitle" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="个人头像：">
          <single-upload v-model="user.avatar"
                         style="width: 300px;display: inline-block;margin-left: 10px"></single-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$router.back()" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('userInfoForm')" size="small">确 定</el-button>
      </span>
  </div>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload'
import {isPhone} from '@/utils/validate';
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'userCenter',
  components: {SingleUpload},
  computed: {
    ...mapGetters(['isLogin', 'userInfo'])
  },
  data() {
    const validateTel = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    };
    return {
      user: Object.assign({}, this.userInfo),
      typeList: ['普通用户', '员工', '经纪人', '管理层'], // 用户类型：0-普通用户；1-员工；2-经纪人；3-管理层
      sexList: ['男', '女'], // 性别：0-男；1-女
      existUsers: [], // 实际已存在的用户列表
      disabledDate(time) {
        return time.getTime() > Date.now()
      },
      rules: {
        realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
        tel: [{required: true, trigger: 'blur', validator: validateTel}],
      },
    }
  },
  methods: {
    ...mapMutations(['SET_ISLOGIN', 'SET_USERINFO']),
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let param = {
              tel: this.user.tel,
              email: this.user.email,
              selfId: this.user.id
            }
            const _this = this;
            this.getRequest('/user/findUserByTel', param).then(resp => {
              _this.existUsers = resp.obj || [];
              if(_this.existUsers.length > 0) {
                let firstUser = _this.existUsers[0];
                let userName = firstUser.realName || '';
                if(firstUser.tel) {
                  userName = userName + ' ' + firstUser.tel;
                }

                this.$message({
                  message: '手机号有重复，请核实后再提交！' + (userName ? '可能重复用户为：' + userName : ''),
                  type: 'warning'
                });

                return;
              }
                _this.postRequest('/user/updateUser', _this.user).then(response => {
                  _this.$message({
                    message: '修改成功！',
                    type: 'success'
                  });
                  this.SET_USERINFO(_this.user)
                  // window.sessionStorage.setItem("user", JSON.stringify(_this.userInfo));
                })
            })
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          });
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>