<template>
<div>
  <div>
    <el-form ref="editForm" status-icon :model="editForm" :rules="projectRules" label-width="180px">
      <div class="form-part-section">
        <el-form-item label="开发商名称" prop="developerName">
          <el-input v-model="editForm.developerName"></el-input>
        </el-form-item>
        <el-form-item label="开发商行业品牌地位">
          <el-input v-model="editForm.developerTitle" placeholder="如中国企业500强"></el-input>
        </el-form-item>
        <el-form-item label="开发商简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="开发商简介"
              v-model="editForm.developerDesc">
          </el-input>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="物业公司名称" prop="propertyName">
          <el-input v-model="editForm.propertyName"></el-input>
        </el-form-item>
        <el-form-item label="物业公司行业品牌地位">
          <el-input v-model="editForm.propertyTitle"></el-input>
        </el-form-item>
        <el-form-item label="物业公司简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="物业公司简介"
              v-model="editForm.propertyDesc">
          </el-input>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="设计公司名称">
          <el-input v-model="editForm.buildDesignName"></el-input>
        </el-form-item>
        <el-form-item label="设计公司行业品牌地位">
          <el-input v-model="editForm.buildDesignTitle"></el-input>
        </el-form-item>
        <el-form-item label="设计公司简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="设计公司简介"
              v-model="editForm.buildDesignDesc">
          </el-input>
        </el-form-item>
      </div>
      <el-form-item style="margin: 60px; text-align: center;">
        <el-button type="primary" @click="toSubmitForm('editForm')">保存</el-button>
         <el-button type="info" @click="goBack">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
const defaultProjectDev = {  //项目开发商表单
  id: null,
  projectId: null,
  developerName: '',
  developerDesc: '',
  developerTitle: '',
  buildDesignName: '',
  buildDesignDesc: '',
  buildDesignTitle: '',
  propertyName: '',
  propertyDesc: '',
  propertyTitle: ''
};
export default {
  name: "developerInfo",
  data() {
    return {
      editForm: Object.assign({}, defaultProjectDev),
      projectRules: {  //表单校验
        developerName: [
          {required: true, message: '请输入开发商名称', trigger: 'blur'}
        ],
        propertyName: [
          {required: true, message: '请输入物业公司名称', trigger: 'blur'}
        ]
      }
    }
  },
  created(e) {
    this.initProject();
  },
  methods: {
    //初始化数据
    initProject() {
        const _this = this
        let queryId = this.$route.query.id;
        this.getRequest('/projectDeveloper/getDevInfo/' + queryId).then(resp => {
          if (resp.obj) {
            _this.editForm = resp.obj;
          } else {
            _this.editForm = Object.assign({}, defaultProjectDev);
            _this.editForm.projectId = queryId;
          }
        })
    },
    goBack() {
      this.$router.push('/admin/allProjects');
    },
    //校验项目基本内容表单
    toSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let url = '/projectDeveloper/updateDeveloper';
          this.postRequest(url, this.editForm).then(resp => {
            if (resp) {
              let alertMsg = "保存成功，点击确定跳转到项目列表页面!";
              alert(alertMsg);
              _this.$router.push('/admin/allProjects')
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>