<template>
<div>
  <div>
    <el-form ref="editForm" status-icon :model="editForm" :rules="projectRules" label-width="180px">
      <div class="form-part-section">
        <el-form-item label="已入驻企业">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="已入驻企业简介"
              v-model="editForm.settleCompanyInfo">
          </el-input>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="总占地面积（㎡）">
          <el-input type="number" v-model="editForm.totalArea"></el-input>
        </el-form-item>
        <el-form-item label="总建筑面积（㎡）">
          <el-input type="number" v-model="editForm.buildArea"></el-input>
        </el-form-item>
        <el-form-item label="地上面积（㎡）">
          <el-input type="number" v-model="editForm.groundArea"></el-input>
        </el-form-item>
        <el-form-item label="地下面积（㎡）">
          <el-input type="number" v-model="editForm.underArea"></el-input>
        </el-form-item>
        <el-form-item label="项目总高（米）">
          <el-input type="number" v-model="editForm.buildHeight"></el-input>
        </el-form-item>
        <el-form-item label="技术指标">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="项目技术指标描述"
              v-model="editForm.techStandard">
          </el-input>
        </el-form-item>
        <el-form-item label="技术指标客户是否可见">
          <el-switch
              v-model="editForm.tsPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="建筑类型构成及层高简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="如集酒店、甲级写字楼、国际文化商业中心三大功能为一体，是一个大体量的商业综合体项目等"
              v-model="editForm.areaDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="不同功能区描述">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="不同功能区描述，如1F-7F商业和文化展览中心等"
              v-model="editForm.moduleDesc">
          </el-input>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="开工日期">
          <el-date-picker
              v-model="editForm.fetchDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开工日期客户是否可见">
          <el-switch
              v-model="editForm.fdPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="竣工日期">
          <el-date-picker
              v-model="editForm.buildDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="竣工日期客户是否可见">
          <el-switch
              v-model="editForm.buildPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
        <el-form-item label="交付日期">
          <el-date-picker
              v-model="editForm.deliverDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开盘日期">
          <el-date-picker
              v-model="editForm.openDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="招商日期">
          <el-date-picker
              v-model="editForm.biDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="预计交房时间">
          <el-input v-model="editForm.deliverDuration"></el-input>
        </el-form-item>
        <el-form-item label="交房时间客户是否可见">
          <el-switch
              v-model="editForm.deliverPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="抗震等级">
          <el-input type="number" v-model="editForm.seismicGrade"></el-input>
        </el-form-item>
        <el-form-item label="抗震等级客户是否可见">
          <el-switch
              v-model="editForm.seismicPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="耐火等级">
          <el-select v-model="editForm.fireGrade" placeholder="请选择">
            <el-option
                v-for="item in fireLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="耐火等级客户是否可见">
          <el-switch
              v-model="editForm.firePublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="楼栋数量">
          <el-input type="number" v-model="editForm.buildNum"></el-input>
        </el-form-item>
        <el-form-item label="楼间距">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="楼间距描述"
              v-model="editForm.buildSpace">
          </el-input>
        </el-form-item>
        <el-form-item label="建筑层高">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="建筑层高描述"
              v-model="editForm.buildFloor">
          </el-input>
        </el-form-item>
        <el-form-item label="标准层高(米)">
          <el-input type="number" v-model="editForm.standardHeight"></el-input>
        </el-form-item>
        <el-form-item label="分层层高">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="分层层高描述"
              v-model="editForm.floorHeight">
          </el-input>
        </el-form-item>
        <el-form-item label="净层高">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="净层高描述"
              v-model="editForm.purFloorHeight">
          </el-input>
        </el-form-item>
        <el-form-item label="标准层面积">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="标准层面积描述"
              v-model="editForm.standardFloorArea">
          </el-input>
        </el-form-item>
        <el-form-item label="分层面积">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="分层面积描述"
              v-model="editForm.floorArea">
          </el-input>
        </el-form-item>
        <el-form-item label="主力面积">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="主力面积描述"
              v-model="editForm.mainFloorArea">
          </el-input>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="最小楼盘售价（元）">
          <el-input type="number" v-model="editForm.minSalePrice"></el-input>
        </el-form-item>
        <el-form-item label="该售价客户是否可见">
          <el-switch
              v-model="editForm.mspPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="楼板价（元）">
          <el-input type="number" v-model="editForm.floorPrice"></el-input>
        </el-form-item>
        <el-form-item label="楼板价客户是否可见">
          <el-switch
              v-model="editForm.fpPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="柱间距">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="项目柱间距描述"
              v-model="editForm.barSpace">
          </el-input>
        </el-form-item>
        <el-form-item label="柱间距客户是否可见">
          <el-switch
              v-model="editForm.bsPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="外墙至核心筒距离（米）">
          <el-input type="number" v-model="editForm.wallCoreWidth"></el-input>
        </el-form-item>
        <el-form-item label="该距离客户是否可见">
          <el-switch
              v-model="editForm.wcPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="房源总套数">
          <el-input type="number" v-model="editForm.totalRoomNum"></el-input>
        </el-form-item>
        <el-form-item label="总套数客户是否可见">
          <el-switch
              v-model="editForm.trnPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="房源构成">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="项目房源构成描述，如住宅XX套，商住XX套等"
              v-model="editForm.roomDetail">
          </el-input>
        </el-form-item>
        <el-form-item label="房源构成客户是否可见">
          <el-switch
              v-model="editForm.rdPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="容积率">
          <el-input type="number" v-model="editForm.rationalFar"></el-input>
        </el-form-item>
        <el-form-item label="容积率客户是否可见">
          <el-switch
              v-model="editForm.rfPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="建筑密度">
          <el-input type="number" v-model="editForm.buildingDensity"></el-input>
        </el-form-item>
        <el-form-item label="建筑密度客户是否可见">
          <el-switch
              v-model="editForm.bdPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="绿化率（%）">
          <el-input type="number" v-model="editForm.greeningRate"></el-input>
        </el-form-item>
        <el-form-item label="绿化率客户是否可见">
          <el-switch
              v-model="editForm.grPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="供电指标">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="供电指标描述"
              v-model="editForm.rsDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="供电指标客户是否可见">
          <el-switch
              v-model="editForm.rsPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="室内照明标准">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="室内照明标准描述"
              v-model="editForm.lqsDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="照明标准客户是否可见">
          <el-switch
              v-model="editForm.lqsPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="供水指标">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="供水指标描述"
              v-model="editForm.wsDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="供水指标客户是否可见">
          <el-switch
              v-model="editForm.wsPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="宽带运营商">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="宽带接入运营商描述，如中国电信、移动等"
              v-model="editForm.internetDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="宽带指定代理商">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="宽带指定代理商描述，如有请填入，如无请留空"
              v-model="editForm.internetSuggest">
          </el-input>
        </el-form-item>
        <el-form-item label="指定代理商客户是否可见">
          <el-switch
              v-model="editForm.interPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
        <el-form-item label="客户是否可自己接入">
          <el-switch
              v-model="editForm.internetSelfFlag"
              active-text="可以"
              inactive-text="禁止">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="每单元预留电话线口数">
          <el-input type="number" v-model="editForm.telLineNum"></el-input>
        </el-form-item>
        <el-form-item label="电话线口数客户是否可见">
          <el-switch
              v-model="editForm.tlnPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
        <el-form-item label="电话线口是否可自增加">
          <el-switch
              v-model="editForm.customTelLine"
              active-text="可以"
              inactive-text="禁止">
          </el-switch>
        </el-form-item>
        <el-form-item label="自增加费用（元/条）" v-if="editForm.customTelLine">
          <el-input type="number" v-model="editForm.customTlPrice"></el-input>
        </el-form-item>
        <el-form-item label="自增加属性客户是否可见">
          <el-switch
              v-model="editForm.ctlPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="空调品牌">
          <el-input v-model="editForm.acBrand"></el-input>
        </el-form-item>
        <el-form-item label="空调运行时间">
          <el-input v-model="editForm.acDuration" placeholder="如工作日9:00-19:00,节假日10:00-19:00等"></el-input>
        </el-form-item>
        <el-form-item label="空调系统简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="空调系统简介"
              v-model="editForm.acDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="空调信息客户是否可见">
          <el-switch
              v-model="editForm.acPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
        <el-form-item label="超时运行费用（元/时）">
          <el-input type="number" v-model="editForm.acOverloadPrice"></el-input>
        </el-form-item>
        <el-form-item label="超时费用客户是否可见">
          <el-switch
              v-model="editForm.aopPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="新风系统品牌">
          <el-input v-model="editForm.mauBrand"></el-input>
        </el-form-item>
        <el-form-item label="新风系统简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="新风系统简介"
              v-model="editForm.mauDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="新风信息客户是否可见">
          <el-switch
              v-model="editForm.mauPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="电梯品牌">
          <el-input v-model="editForm.elevBrand"></el-input>
        </el-form-item>
        <el-form-item label="电梯系统简介">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="电梯系统简介"
              v-model="editForm.elevDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="电梯是否分区运行">
          <el-switch
              v-model="editForm.elevSplitFlag"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="电梯是否信号覆盖">
          <el-switch
              v-model="editForm.elevSignFlag"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="客梯数量">
          <el-input type="number" v-model="editForm.elevNum"></el-input>
        </el-form-item>
        <el-form-item label="客梯梯速">
          <el-input v-model="editForm.elevSpeed" placeholder="客梯梯速简介"></el-input>
        </el-form-item>
        <el-form-item label="客梯轿厢净尺寸(宽*深*高 m)">
          <el-input v-model="editForm.elevSize" placeholder="请输入客梯轿厢净尺寸"></el-input>
        </el-form-item>
        <el-form-item label="客梯载重">
          <el-input v-model="editForm.elevBear" placeholder="请输入客梯最大载重简介"></el-input>
        </el-form-item>
        <el-form-item label="货梯数量">
          <el-input type="number" v-model="editForm.cargoElevNum"></el-input>
        </el-form-item>
        <el-form-item label="货梯梯速">
          <el-input v-model="editForm.cargoElevSpeed" placeholder="货梯梯速简介"></el-input>
        </el-form-item>
        <el-form-item label="货梯轿厢净尺寸(宽*深*高 m)">
          <el-input v-model="editForm.cargoElevSize" placeholder="请输入货梯轿厢净尺寸"></el-input>
        </el-form-item>
        <el-form-item label="货梯载重">
          <el-input v-model="editForm.cargoElevBear" placeholder="请输入货梯最大载重简介"></el-input>
        </el-form-item>
        <el-form-item label="电梯信息客户是否可见">
          <el-switch
              v-model="editForm.elevPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="卫生间情况">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="卫生间情况简介"
              v-model="editForm.toiletDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="卫生间信息客户是否可见">
          <el-switch
              v-model="editForm.toiletPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="是否具有备用发电">
          <el-switch
              v-model="editForm.backupElecFlag"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="触发备用发电条件" v-if="editForm.backupElecFlag">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="触发备用发电条件简介"
              v-model="editForm.beSupportDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="备用发电信息客户是否可见">
          <el-switch
              v-model="editForm.bePublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>
      <div class="form-part-section">
        <el-form-item label="机房水冷系统">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="机房水冷系统简介，无则留空"
              v-model="editForm.waterColdDesc">
          </el-input>
        </el-form-item>
        <el-form-item label="水冷系统信息客户是否可见">
          <el-switch
              v-model="editForm.wcdPublic"
              active-text="可见"
              inactive-text="隐藏">
          </el-switch>
        </el-form-item>
      </div>

      <el-form-item style="margin: 60px; text-align: center;">
        <el-button type="primary" @click="toSubmitForm('editForm')">保存</el-button>
         <el-button type="info" @click="goBack">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
const defaultProjectExt = {  //项目具体表单
  id: null,
  projectId: null,
  floorPrice: null,
  fpPublic: false,
  totalArea: null,
  buildArea: null,
  groundArea: null,
  underArea: null,
  buildHeight: null,
  techStandard: '',
  tsPublic: true,
  barSpace: '',
  bsPublic: true,
  wallCoreWidth: null,
  wcPublic: true,
  totalRoomNum: null,
  roomDetail: '',
  trnPublic: true,
  rdPublic: true,
  rationalFar: null,
  rfPublic: true,
  buildingDensity: null,
  bdPublic: true,
  greeningRate: null,
  grPublic: true,
  // belongCbd: '',
  // trafficInfo:'',
  settleCompanyInfo:null,
  fetchDate: null,
  fdPublic: true,
  buildDate: null,
  buildPublic: true,
  deliverDate: null,
  openDate: null,
  biDate: null,
  minSalePrice: null,
  mspPublic: true,
  areaDesc: '',
  moduleDesc: '',
  deliverDuration: '现房交付',
  deliverPublic: true,
  seismicGrade: null,
  seismicPublic: true,
  fireGrade: 0,
  firePublic: true,
  buildNum: null,
  buildSpace: null,
  buildFloor: null,
  standardHeight: null,
  floorHeight: null,
  purFloorHeight: null,
  standardFloorArea: null,
  floorArea: null,
  mainFloorArea: null,
  rsDesc: '',
  rsPublic: true,
  lqsDesc: '',
  lqsPublic: true,
  wsDesc: '',
  wsPublic: true,
  internetDesc: '',
  internetSuggest: '',
  internetSelfFlag: true,
  interPublic: true,
  telLineNum: 0,
  tlnPublic: true,
  customTelLine: true,
  customTlPrice: null,
  ctlPublic: true,
  acBrand: '',
  acDesc: '',
  acDuration: '',
  acOverloadPrice: null,
  aopPublic: true,
  acPublic: true,
  mauBrand: '',
  mauDesc: '',
  mauPublic: true,
  elevBrand: '',
  elevDesc: '',
  elevNum: 0,
  elevSpeed: null,
  elevSize: null,
  elevBear: null,
  cargoElevNum: null,
  cargoElevSpeed: null,
  cargoElevSize: null,
  cargoElevBear: null,
  elevSplitFlag: true,
  elevSignFlag: true,
  elevPublic: true,
  toiletDesc: '',
  toiletPublic: true,
  backupElecFlag: true,
  beSupportDesc: null,
  bePublic: true,
  waterColdDesc: '',
  wcdPublic: true
};
export default {
  name: "projectExtInfo",
  data() {
    return {
      editForm: Object.assign({}, defaultProjectExt),
      fireLevelOptions: [{
        value: 0,
        label: '未知'
      }, {
        value: 1,
        label: '一级'
      }, {
        value: 2,
        label: '二级'
      }, {
        value: 3,
        label: '三级'
      }, {
        value: 4,
        label: '四级'
      }, {
        value: 9,
        label: '其他'
      }],
      projectRules: {  //表单校验
      }
    }
  },
  created(e) {
    this.initProject();
  },
  methods: {
    //初始化数据
    initProject() {
        const _this = this
        let queryId = this.$route.query.id;
        this.getRequest('/projectExt/getExtInfo/' + queryId).then(resp => {
          if (resp.obj) {
            _this.editForm = resp.obj;
          } else {
            _this.editForm = Object.assign({}, defaultProjectExt);
            _this.editForm.projectId = queryId;
          }
        })
    },
    goBack() {
      this.$router.push('/admin/allProjects');
    },
    //校验项目基本内容表单
    toSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let url = '/projectExt/updateExt';
          this.postRequest(url, this.editForm).then(resp => {
            if (resp) {
              let alertMsg = "保存成功，点击确定跳转到项目列表页面!";
              alert(alertMsg);
              _this.$router.push('/admin/allProjects')
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>