<template>
  <project-detail :is-edit="true"></project-detail>
</template>
  <script>
  import projectDetail from '@/components/projects/projectDetail'
    export default {
      name: "EditProject",
      components:{projectDetail}
    }
  </script>
  
  <style>
  </style>
  