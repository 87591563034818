<template>
  <project-detail :is-edit="false"></project-detail>
</template>
<script>
import projectDetail from '@/components/projects/projectDetail'
  export default {
    name: "WriteProject",
    components:{projectDetail}
  }
</script>

<style>

</style>
