<template>
  <div>
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>{{ deptName }}成员列表</span>
      <el-button size="mini" type="info" plain @click="handleBackToTop()" style="margin-left: 20px">
        返回上级
      </el-button>
<!--      <el-button size="mini" class="btn-add" @click="handleAdd()" style="margin-left: 20px">添加</el-button>-->
    </el-card>
    <div class="table-container">
      <el-table ref="memberTable"
                :data="list"
                style="width: 100%;"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">{{ scope.row.realName }}</template>
        </el-table-column>
        <el-table-column label="岗位" align="center">
          <template slot-scope="scope">{{ scope.row.deptTitle }}</template>
        </el-table-column>
        <el-table-column label="联系电话" align="center">
          <template slot-scope="scope">{{ scope.row.tel }}</template>
        </el-table-column>
        <el-table-column label="入职时间" align="center">
          <template slot-scope="scope">{{ scope.row.importTime | formatDate("yyyy-MM-dd") }}</template>
        </el-table-column>
        <el-table-column label="办公区域" align="center">
          <template slot-scope="scope">{{ scope.row.workArea }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="text"
                       @click="handleUpdate(scope.$index, scope.row)">
              完善信息
            </el-button>
            <el-button size="mini"
                       type="text"
                       @click="handleDelete(scope.$index, scope.row)">离职
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :current-page.sync="listQuery.pageNum"
          :page-size="listQuery.pageSize"
          :page-sizes="[10,15,20]"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        title="完善成员信息"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="editForm"
               ref="editMemberForm"
               :rules="userRules"
               label-width="100px" size="small">
        <el-form-item label="成员：">
          <div>{{editForm.realName}}</div>
        </el-form-item>
        <el-form-item label="办公区域">
          <el-input v-model="editForm.workArea"></el-input>
        </el-form-item>
        <el-form-item label="岗位">
          <el-input v-model="editForm.deptTitle"></el-input>
        </el-form-item>
        <el-form-item label="入职日期">
          <el-date-picker clearable
                          v-model="editForm.importTime"
                          type="date"
                          style="width:100%;"
                          value-format="yyyy-MM-dd"
                          :picker-options="fillTimePickerOptions"
                          placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm('editMemberForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

const defaultMember = {  //项目具体成员
  id: null,
  deptId: null,
  userId: null,
  isLeader: false,
  realName: null,
  tel: null,
  workArea: null,
  importTime: null,
  deptTitle: null
};
export default {
  name: "deptMemberList",
  data() {
    return {
      listQuery: {
        deptId: null,
        keyword: null,
        pageNum: 1,
        pageSize: 10
      },
      list: null,
      total: null,
      workArea: null,
      listLoading: false,
      isEdit: false,
      dialogVisible: false,
      deptName: '',
      deptId: null,
      editForm: Object.assign({}, defaultMember),
      userRules: {  //表单校验
        // userId: [
        //   {required: true, message: '请选择部门成员', trigger: 'blur'}
        // ]
      },
      fillTimePickerOptions: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      }
    }
  },
  created() {
    this.listQuery.deptId = this.$route.query.id;
    this.deptId = this.$route.query.id;
    this.deptName = this.$route.query.name || '';
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleBackToTop() {
      this.$router.back();
    },
    handleAdd() {
      this.isEdit = false;
      this.editForm = Object.assign({}, defaultMember);
      this.editForm.deptId = this.deptId;
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm('是否确认该成员离职?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/dept/deleteMember/' + row.id).then(response => {
          this.$message({
            type: 'success',
            message: '离职成功!'
          });
          this.getList();
        });
      });
    },
    handleUpdate(index, row) {
      this.isEdit = true;
      this.editForm = row;
      this.editForm.workArea = this.workArea;
      this.dialogVisible = true;
    },
    getList() {
      this.listLoading = true;
      this.getRequest('/dept/deptMemberList', this.listQuery).then(response => {
        this.listLoading = false;
        this.list = response.obj.list;
        this.total = response.obj.total;
        if (this.list.length > 0 && !this.workArea) {
          this.workArea = this.list[0].workArea || null;
        }
      });
    },
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要确认?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let url = '/dept/updateDeptMember';
            if(this.editForm.importTime) {
              this.editForm.importTime = this.editForm.importTime + ' 00:00:00'
            }
            this.postRequest(url, this.editForm).then(resp => {
              this.$message({
                message: '更新成功！',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            })
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>