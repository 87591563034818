import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        baseUrl: 'http://localhost:9080/',
        accessToken: '',
        sessionKey: '', // 微信登录后的session key
        userInfo: {},
        isLogin: false
    },
    getters: {
        accessToken(state) {
            return state.accessToken;
        },
        sessionKey(state) {
            return state.sessionKey;
        },
        userInfo(state) {
            return state.userInfo;
        },
        isLogin: state => (state.userInfo && state.userInfo.id && Object.keys(state.userInfo).length > 0)	//是否登录
    },
    mutations: {
        SET_USERINFO(state, userInfo) {
            state.userInfo = userInfo
        },
        SET_ACCESSTOKEN(state, accessToken) {
            state.accessToken = accessToken
        },
        SET_SESSIONKEY(state, sessionKey) {
            state.sessionKey = sessionKey
        },
        SET_ISLOGIN(state, isLogin) {
            state.isLogin = isLogin
        }
    }
})

export default store
