<template>
  <div>
        <!-- 项目 -->
        <el-table
            v-loading="loading"
            element-loading-text="正在加载..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="projectsData"
            stripe
            style="width: 100%">
            <el-table-column label="项目列表">
            <template slot-scope="scope">
                <el-card  class="box-card">
                <div style="font-size: 18px;">
                    <!-- v-if="!scope.row.shareStatement" 这里根据shareStatement属性判断项目是否为草稿 -->
                    <el-button size="mini" v-if="!scope.row.shareStatement" type="info" icon="el-icon-edit" circle></el-button>
                    {{scope.row.title}}
                </div>
                <div style="margin-top: 10px;">
                  <div>
                    <el-tag size="small" style="margin-right: 10px;"  v-if="scope.row.isDelete" type="danger">已删除</el-tag>
                    <el-tag size="small" style="margin-right: 10px;"  v-if="!scope.row.shareStatement" type="warning">草稿</el-tag>
                    <el-tag style="margin-right: 10px;" v-if="scope.row.shareStatement" size="small">{{scope.row.flag === 0 || scope.row.flag === '0' ? '原创':'转载'}}</el-tag>
                    <el-tag style="margin-right: 20px;" v-if="scope.row.shareStatement" size="small" type="info">{{scope.row.published === 0 || scope.row.published === '0' ? '内部' : '公开'}}</el-tag>
                    <el-tag style="margin-right: 10px;" size="small" type="success">{{scope.row.ttype?scope.row.ttype.name: '--'}}</el-tag>
                    <i style="margin-right: 20px;" class="el-icon-location" v-if="scope.row.areaName"> {{scope.row.areaName}} </i>
                    <i style="margin-right: 20px;" class="el-icon-s-flag"> {{projStatusList[scope.row.projStatus?scope.row.projStatus:0]}} </i>
                    <i style="margin-right: 20px;" class="el-icon-view"> {{scope.row.views}} </i>
                    <i style="margin-right: 20px;" class="el-icon-chat-square"> {{scope.row.commentCount}} </i>
                    <i style="margin-right: 20px;" class="el-icon-date"> {{scope.row.createTime}}</i>
                  </div>
                  <div style="padding-top: 10px;padding-bottom: 20px;">
                    <el-button v-if="!scope.row.isDelete" style="float: right;" type="danger" size="mini" @click="logicDeleteProject(scope.row.id)">删除</el-button>
<!--                    <router-link :to=" '/admin/editProject?id=' + scope.row.id ">-->
                      <el-button v-if="!scope.row.isDelete" @click="goToEditPage(scope.row.id)" style="float: right; margin-right: 10px;" type="primary" size="mini">编辑</el-button>
                      <el-button v-if="!scope.row.isDelete" @click="goToEditDevPage(scope.row.id)" style="float: right; margin-right: 10px;" type="success" size="mini">编辑开发商信息</el-button>
                      <el-button v-if="!scope.row.isDelete" @click="goToEditExtPage(scope.row.id)" style="float: right; margin-right: 10px;" type="success" size="mini">编辑楼盘信息</el-button>
                      <el-button v-if="!scope.row.isDelete" @click="goToEditItemPage(scope.row.id)" style="float: right; margin-right: 10px;" type="success" size="mini">编辑户型信息</el-button>
                      <el-button v-if="!scope.row.isDelete" @click="goToEditMemberPage(scope.row.id)" style="float: right; margin-right: 10px;" type="success" size="mini">编辑配套团队</el-button>
<!--                    </router-link>-->
                    <el-button v-if="scope.row.isDelete" style="float: right;" type="danger" size="mini" @click="deleteProject(scope.row.id)">删除</el-button>
                    <el-button v-if="scope.row.isDelete" style="float: right; margin-right: 10px;" @click="recoveryProject(scope.row.id)" type="primary" size="mini">还原</el-button>
                  </div>
                </div>

                </el-card>
            </template>
                
            </el-table-column>
        </el-table>

       <!-- 分页 -->
       <div style="margin-top: 20px;">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
       </div>
 
  </div>
</template>

<script>
export default {
  name: 'ProjectListCom',
  data () {
   return {
    projectsData: [], //项目数据
    currentPage: 1,  //当前页
    total:0, //总记录数
    pageSize:10, //页面大小
    pageSizes:[10,20,30], //页面数组
    t2index:0, //选项卡index
    loading:false, //加载样式
     projStatusList: ['进行中', '即将开始', '即将完成', '已完成'],
   }
  },
  props:["tindex", "activeType"],
  mounted() {
    this.initIndex();
    this.initProjects(); // 调用初始化项目数据
    
  },
  
  methods:{
    //删除项目(逻辑删除)
    logicDeleteProject(id){
      const _this = this
      this.$confirm('此操作将删除该项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteRequest('/project/logicDeleteProject?id=' + id).then(resp=>{
            alert("删除成功！")
            location.reload(true); // 刷新当前页面
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    goToEditPage(id) {
      this.$router.push({path:'/admin/editProject',query:{id:id}});
    },
    goToEditDevPage(id) {
      this.$router.push({path:'/admin/editProjectDev',query:{id:id}});
    },
    goToEditExtPage(id) {
      this.$router.push({path:'/admin/editProjectExt',query:{id:id}});
    },
    goToEditItemPage(id) {
      this.$router.push({path:'/admin/projectItems',query:{id:id}});
    },
    goToEditMemberPage(id) {
      this.$router.push({path:'/admin/projectMembers',query:{id:id}});
    },
    //彻底删除项目
    deleteProject(id){
      const _this = this
      this.$confirm('此操作将彻底删除该项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteRequest('/project/deleteProject?id=' + id).then(resp=>{
            alert("删除成功！")
            location.reload(true); // 刷新当前页面
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    //还原项目
    recoveryProject(id){
      const _this = this
      this.$confirm('确定要还原该项目吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest('/project/recoveryProject?id=' + id).then(resp=>{
            alert("还原成功！")
            location.reload(true); // 刷新当前页面
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消还原'
          });          
        });
    },
    //组件的index
    initIndex(){
        this.t2index = this.tindex  //保存父组件传过来的值
    },
    // 初始化【全部】项目的数据
    initProjects(){
      const _this = this
      // 通用路由
      var baseurl = '/project/getByPage?current=' + this.currentPage + '&size=' + this.pageSize
      //通过条件拼接路由
      if(this.activeType == "0"){ //全部
        baseurl +='&is_delete=0'
      } else if(this.activeType == "-1"){ //回收站
        baseurl +='&is_delete=1'
      } else {
        baseurl +='&is_delete=0&type=' + this.activeType
      }

      // console.log(baseurl)
      //显示加载样式
      this.loading = true
      this.getRequest(baseurl).then(resp=>{
        // console.log(resp)
        if(resp){
          //隐藏加载样式
          _this.loading = false
          _this.projectsData = resp.obj.records  //将获取到的后端的值赋值给projectsData
          _this.total = resp.obj.total   // 保存一下总记录数，用于前端展示
        }
      })
    },
    // 分页的当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.initProjects()
    },
    //每页多少条
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize = val
        this.initProjects()
    },
 
  }
}
</script>

<style scoped>

</style>